export const getTodo = `query GetTodo($id: ID!) {
  getTodo(id: $id) {
    id
    name
    description
  }
}
`;
export const listTodos = `query ListTodos(
  $filter: ModelTodoFilterInput
  $limit: Int
  $nextToken: String
) {
  listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
    }
    nextToken
  }
}
`;
export const getMenuItem = `query GetMenuItem($id: ID!) {
  getMenuItem(id: $id) {
    id
    title
    cognitoId
    deviceId
    color
    imagePath
    logoPath
    typ
    installationId
  }
}
`;
export const listMenuItems = `query listMenuItems(
  $cognitoId: ID!
  $limit: Int
  $nextToken: String
) {
  listMenuItems(filter: {cognitoId:{ge:$cognitoId}}, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      cognitoId
      deviceId
      color
      imagePath
      logoPath
      typ
      installationId
      order
      
    }
    nextToken
  }
}
`;

export const listGriddatac = `query ListGriddatas(
  $filter: ModelGriddataFilterInput
  $limit: Int
  $nextToken: String
) {
  listGriddatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      device
      i
      x
      y
      w
      h
      isResizable
      minW
      maxW
      minH
      maxH
      ownId
      title
          chartAxes {
        items {
      id
      sid
      GriddataId
      name
      yAxis
      visible
      colum
      color
      table
      withoutresolution
      standardInterval
    }
    nextToken
  }
    }
    nextToken
  }
}
`;


export const listStartGraphicsc = `query ListStartGraphics(
  $filter: ModelStartGraphicFilterInput
  $limit: Int
  $nextToken: String
) {
  listStartGraphics(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      typ
      width
      height
      left
      top
      imagepath
      imagealttag
      deviceID
      valueName
      valueUnit
      valueAdd
      valueIdentifierName
    }
    nextToken
  }
}
`;
