import React, {Component} from "react";
import {Link} from "react-router-dom";
import LiveData from "./LiveData";
import * as PropTypes from "prop-types";

class MenuItem extends Component {

    constructor(props) {
        super(props);
        this._state = {
            lastId: 0,
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.data.deviceId === nextProps.currentId || this._state.lastId !== nextProps.currentId; // equals() is your implementation
    }

    componentDidMount() {
        this.setState({lastId: this.props.currentId});

    }

    render() {
        return <div style={{margin: "0px 0px 0px 0px", position: "relative"}}>
            <Link
                to={"/device/" + this.props.data.installationId + "/" + this.props.data.typ + "/" + this.props.data.deviceId + "/"}
                onClick={this.props.onClick}>
                <div className="card text-white bg-flat-color-3" style={{
                    width: "310px",
                    height: "105px",
                    background: "#" + this.props.data.color,
                    boxShadow: this.props.currentId === this.props.data.deviceId ? ("-2px 10px 50px -7px #FFFFFF") : ("0px 3px 6px rgba(0, 0, 0, 0.07)"),
                }}>
                    <div className="card-body">
                        <img className="card-left"
                             src={this.props.data.imagePath}
                             style={{width: "80px", height: "80px", marginTop: "-9px"}}
                             alt="netzicon"/>
                        <div className="card-right float-right text-right">
                            <img
                                src={this.props.data.logoPath}
                                style={{
                                    width: "190px",
                                    marginRight: "-9px",
                                    marginTop: "-15px"
                                }} alt="erenogridlogo"/>
                            <div className="mb-0 fw-r">
                                <p className="text-light mt-1 m-0">P<sub>akt</sub>:
                                    <LiveData device={"currentDevice"} deviceID={this.props.data.deviceId} name={"kWL1"}
                                              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>
                                </p>
                            </div>
                        </div>
                        {/* /.card-right */}
                    </div>
                </div>
            </Link>
        </div>;
    }
}


MenuItem.propTypes = {
    onClick: PropTypes.func,
    currentId: PropTypes.number
};

export default MenuItem;