import {Component} from 'react';
import {store} from "../App";

class LiveData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            messages: [],
        };
    }

    componentDidMount() {
        store.subscribe(() => this.setState({messages: store.getState().websocketData, loaded: true}))
    }

    render() {
        if (this.props.add === null) {
            return (
                this.state.loaded ? ((this.state.messages[this.props.deviceID][this.props.name]).toFixed(this.props.decimals)) + this.props.unit : (0).toFixed(this.props.decimals) + this.props.unit
            )
        } else {

            if (this.state.loaded) {
                let output = 0;
                output += (this.state.messages[this.props.deviceID][this.props.name]);
                this.props.add.map(x => (output += this.state.messages[this.props.deviceID][x]));
                return (output.toFixed(this.props.decimals) + this.props.unit)
            } else {
                return ((0).toFixed(this.props.decimals) + this.props.unit)
            }

        }

    }
}

export default LiveData;