import React, {Component} from "react";
import '../../node_modules/react-grid-layout/css/styles.css'
import {Link} from "react-router-dom";
import {API, Auth, graphqlOperation} from 'aws-amplify';
import * as customQueries from "../graphql/queries";
import MenuItem from "./MenuItem";
import * as mutations from "../graphql/mutations";
import {DndProvider} from "react-dnd";
import Backend from "react-dnd-html5-backend";
import Container from "./Container";

class Admin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uselese: 1,
            loaded: false,
            currentId: 0,
            userSub: null,
            menuStructure: null,
            deviceId: 0,
            userId: "555fab62-2a2b-4255-a8f5-cdf0deb9fd5b",
            userIdOld: "555fab62-2a2b-4255-a8f5-cdf0deb9fd5b",
        };
    }

    componentDidMount() {
        Auth.currentSession().then(data => this.setState({currentId: data.idToken.payload.sub})).catch(err => console.log(err))
    }

    async getData() {
        const allTodos = await API.graphql(graphqlOperation(customQueries.listMenuItems, {filter: {cognitoId: {eq: this.state.userId}}}));
        this.setState({menuStructure: allTodos.data.listMenuItems});
        this.setState({loaded: true, userIdOld: this.state.userId});
    }

    async cangeValue(e, key, valueName) {
        let old = this.state.menuStructure.items[key];
        old[valueName] = e.target.value;
        await API.graphql(graphqlOperation(mutations.updateMenuItem, {"input": old})).then(this.setState({loaded: false}));
        this.setState({})
    }

    async addElement(type) {
        let theme = ""
        switch (type) {
            case "grid":
                theme = {
                    title: "Netzanschluss",
                    cognitoId: this.state.userId,
                    deviceId: 1,
                    color: "c46767",
                    imagePath: "https://portal.ereneo.de/images/icon/1.png",
                    logoPath: "https://portal.ereneo.de/images/logos/ereno_grid_logo_wei%c3%9f@4x.png",
                    typ: "CGET340",
                    installationId: 1111,
                    order: 11
                };
                break;
            case "sun":
                theme = {
                    title: "PV-Anlage",
                    cognitoId: this.state.userId,
                    deviceId: 3,
                    color: "FFC107",
                    imagePath: "https://portal.ereneo.de/images/icon/3.png",
                    logoPath: "https://portal.ereneo.de/images/logos/ereno_sun_Logo_wei%c3%9f@4x.png",
                    typ: "CGET340",
                    installationId: 1111,
                    order: 12
                };
                break;
            case "mainmonitoring":
                theme = {
                    title: "Hausverbraucher",
                    cognitoId: this.state.userId,
                    deviceId: 4,
                    color: "271D67",
                    imagePath: "https://portal.ereneo.de/images/icon/4.png",
                    logoPath: "https://portal.ereneo.de/images/logos/monitoringwei%c3%9f_@4x.png",
                    typ: "CGET340",
                    installationId: 1111,
                    order: 13
                };
                break;
            case "monitoring_elektroauto":
                theme = {
                    title: "Ladestation Elektroauto",
                    cognitoId: this.state.userId,
                    deviceId: 5,
                    color: "271D67",
                    imagePath: "https://portal.ereneo.de/images/icon/6.png",
                    logoPath: "https://portal.ereneo.de/images/logos/monitoringwei%c3%9f_@4x.png",
                    typ: "CGET340",
                    installationId: 1111,
                    order: 14
                };
                break;
            case "monitoring_waschmaschine":
                theme = {
                    title: "Waschmaschine",
                    cognitoId: this.state.userId,
                    deviceId: 6,
                    color: "271D67",
                    imagePath: "https://portal.ereneo.de/images/icon/5.png",
                    logoPath: "https://portal.ereneo.de/images/logos/monitoringwei%c3%9f_@4x.png",
                    typ: "CGET340",
                    installationId: 1111,
                    order: 15
                };
                break;
            case "storage":
                theme = {
                    title: "ereneo sun storage",
                    cognitoId: this.state.userId,
                    deviceId: 6,
                    color: "0bbbef",
                    imagePath: "https://portal.ereneo.de/images/icon/2.png",
                    logoPath: "https://portal.ereneo.de/images/logos/ereno_storage_Logo_wei%c3%9f@4x.png",
                    typ: "CGET340",
                    installationId: 1111,
                    order: 16
                };
                break;


        }
        await API.graphql(graphqlOperation(mutations.createMenuItem, {"input": theme}))
        this.setState({loaded: false})
    }

    async deleteElement(id) {
        await API.graphql(graphqlOperation(mutations.deleteMenuItem, {"input": {id: id}})).then(this.setState({loaded: false}));
    }

    render() {
        if (this.state.loaded !== true || this.state.userId !== this.state.userIdOld) {
            this.getData()
            console.log(this.state.userId)
            console.log(this.state.userIdOld)
            console.log(this.state.userId !== this.state.userIdOld)
        }
        return (<div className="content mt-3" style={{padding: '1% 3% 1% 3%'}}>
            <div style={{padding: '0% 1% 0% 1%'}}>
                <div style={{
                    fontFamily: '"Roboto", serif',
                    marginBottom: '0px',
                    marginTop: '-10px',
                }}>
                    <div className="col-lg-9"><h2 style={{
                        fontFamily: '"Roboto", serif',
                        marginBottom: '-5px',
                        marginLeft: '-1%',
                    }}> Settings </h2></div>

                    <div className="col-lg-3">
                        <ul className="nav nav-pills float-right" style={{marginBottom: '-5px'}}>
                            <li className="nav-item  active show">
                                <a className="nav-link" id="pills-home-tab" data-toggle="pill" href={"# "}
                                   role="tab"
                                   aria-controls="pills-home" aria-selected="true">Übersicht</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href={"# "}
                                   role="tab" aria-controls="pills-profile" aria-selected="false">Erweitert</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <hr/>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <strong className="card-title">User Auswahl </strong>
                    </div>
                    <div className="card-body">

                        <div className="col-3">
                            <small>User Id:</small>
                            <input
                                className={"form-control"}

                                type={"text"}
                                value={this.state.userId}
                                onChange={e => {
                                    this.setState({
                                        userId: e.target.value
                                    })
                                }}
                            /></div>


                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <strong className="card-title">User Auswahl </strong>
                    </div>
                    <div className="card-body">
                        <div className="row form-group">
                            <div className="col-6">
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.addElement("grid")}>Add Grid
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.addElement("sun")}>Add Sun
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.addElement("mainmonitoring")}>Add mainmonitoring
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.addElement("monitoring_elektroauto")}>Add
                                    monitoring_elektroauto
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.addElement("monitoring_waschmaschine")}>Add
                                    monitoring_waschmaschine
                                </button>
                                <button type="button" className="btn btn-primary"
                                        onClick={() => this.addElement("storage")}>Add storage
                                </button>
                                <div style={{
                                    width: "430px",
                                    height: "600px",
                                    overflowX: 'auto',
                                    textAlign: 'justify',
                                }}>
                                    {(this.state.loaded === true) ? (Object.keys(this.state.menuStructure.items).map(key =>
                                        <div>

                                            <h7>{this.state.menuStructure.items[key].title}</h7>
                                            <button type="button" className="btn btn-primary"
                                                    onClick={() => this.deleteElement(this.state.menuStructure.items[key].id)}>
                                                deleteElement
                                            </button>
                                            <hr/>
                                            <div className="row form-group">
                                                <div className="col-12">
                                                    <small>title:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"text"}
                                                        value={this.state.menuStructure.items[key].title}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "title")
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <small>deviceId:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"number"}
                                                        value={this.state.menuStructure.items[key].deviceId}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "deviceId")
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <small>color:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"text"}
                                                        value={this.state.menuStructure.items[key].color}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "color")
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <small>imagePath:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"text"}
                                                        value={this.state.menuStructure.items[key].imagePath}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "imagePath")
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <small>logoPath:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"text"}
                                                        value={this.state.menuStructure.items[key].logoPath}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "logoPath")
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <small>installationId:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"number"}
                                                        value={this.state.menuStructure.items[key].installationId}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "installationId")
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <small>order:</small>
                                                    <input
                                                        className={"form-control"}
                                                        type={"number"}
                                                        value={this.state.menuStructure.items[key].order}
                                                        onChange={e => {
                                                            this.cangeValue(e, key, "order")
                                                        }}
                                                    />
                                                </div>


                                            </div>
                                            <hr/>
                                        </div>
                                    )) : (<div>loading</div>)}


                                </div>
                            </div>

                            <div className="col-6">
                                {this.state.loaded ? (
                                    this.state.menuStructure.items.sort((a, b) => a.order - b.order).map(item =>
                                        <MenuItem
                                            currentId={this.state.deviceId} data={item} key={item.order}/>)
                                ) : (<div>loading..</div>)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card" id="mainpart">
                <div id="opend" className="card-header">
                    <strong className="card-title"> <span className="float-right mt-2"><a href={"# "}
                    >
                        <span className="ti-fullscreen"/>
                      </a></span><a href={"# "}>
                    </a>
                    </strong>
                </div>
                <div className="card-body">
                    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap"
                          rel="stylesheet"/>


                    <DndProvider backend={Backend}>

                        <Container hideSourceOnDrag={true}/>

                    </DndProvider>


                    <style
                        dangerouslySetInnerHTML={{__html: "\n\n        .close {\n            position: absolute;\n            right: 32px;\n            top: 32px;\n            width: 20px;\n            height: 20px;\n            opacity: 0.3;\n        }\n        .close:hover {\n            opacity: 1;\n        }\n        .close:before, .close:after {\n            position: absolute;\n            left: 15px;\n            content: ' ';\n            height: 33px;\n            width: 2px;\n            background-color: #333;\n        }\n        .close:before {\n            transform: rotate(45deg);\n        }\n        .close:after {\n            transform: rotate(-45deg);\n        }\n\n\n        .box {\n        background-color: white;\n        width: 1200px;\n        height: 550px;\n        position: relative;\n\n\n    }\n\n    .part1 {\n        position: absolute;\n        left: 0px;\n        top: 0px;\n        background-color: blanchedalmond;\n        width: 30px;\n        height: 30px;\n        margin: 0;\n    }\n\n    .part2 {\n        position: absolute;\n        left: 30px;\n        top: 10px;\n        background-color: blanchedalmond;\n        width: 30px;\n        height: 30px;\n    }\n\n    .image {\n        position: absolute;\n        z-index: 10;\n    }\n\n    .line {\n        position: absolute;\n       background-color: black;\n    }\n\n    .valuebox {\n        z-index: 11;\n box-shadow:        -1px 0px 18px 0px rgba(0, 0, 0, 0.1);\n      position: absolute;\n        text-align: center;\n        background-color: #b9da8f;\n        color: white;\n        width: 120px;\n        border-radius: 20px;\n        padding-top: 5px;\n        padding-bottom: 5px;\n        font-family: 'Roboto', serif;\n        font-weight:300;\n } "}}/>
                </div>
            </div>
            <Link
                to={"./erweitert"}>
                <button type="button" className="btn btn-primary">Mehr Daten…</button>
            </Link>
            <style media="screen"
                   dangerouslySetInnerHTML={{__html: "\n        /* Dropdown Button */\n        .dropbtn {\n            background-color: #3498DB;\n            color: white;\n            padding: 6px;\n            font-size: 15px;\n            border: none;\n            cursor: pointer;\n            width: 190px;\n        }\n\n        /* Dropdown button on hover & focus */\n        .dropbtn:hover, .dropbtn:focus {\n            background-color: #2774a8;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);\n\n        }\n\n        /* The container <div> - needed to position the dropdown content */\n        .dropdown {\n            position: relative;\n            display: inline-block;\n        }\n\n        /* Dropdown Content (Hidden by Default) */\n        .dropdown-content {\n            display: none;\n            padding: 13px;\n            position: absolute;\n            background-color: #f1f1f1;\n            width: 50vh;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n            z-index: 1;\n            right: 0%;\n        }\n\n        /* Links inside the dropdown */\n        .dropdown-content a {\n            color: black;\n            padding: 12px 16px;\n            text-decoration: none;\n            display: block;\n        }\n\n        /* Change color of dropdown links on hover */\n        .dropdown-content a:hover {\n            background-color: #ddd\n        }\n\n        /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */\n        .show {\n            display: block;\n        }\n    "}}/>
        </div>);
    }


}

export default Admin;
