import React, {Component} from "react";
import '../../node_modules/react-grid-layout/css/styles.css'
import {Link} from "react-router-dom";
import {API, Auth, graphqlOperation} from 'aws-amplify';
import * as customQueries from "../graphql/queries";

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            currentId: 0,
            userSub: null,
            menuStructure: null,
            deviceId: 0,
        };
    }

    componentDidMount() {
        Auth.currentSession().then(data => this.setState({currentId: data.idToken.payload.sub})).catch(err => console.log(err))
    }

    async getData() {
        const allTodos = await API.graphql(graphqlOperation(customQueries.listMenuItems, {cognitoId: this.state.currentId}));
        this.setState({menuStructure: allTodos.data.listMenuItems});
        this.setState({loaded: true});
    }


    render() {
        if (this.state.loaded !== true) {
            this.getData()
        }
        return (<div className="content mt-3" style={{padding: '1% 3% 1% 3%'}}>
            <div style={{padding: '0% 1% 0% 1%'}}>
                <div style={{
                    fontFamily: '"Roboto", serif',
                    marginBottom: '0px',
                    marginTop: '-10px',
                }}>
                    <div className="col-lg-9"><h2 style={{
                        fontFamily: '"Roboto", serif',
                        marginBottom: '-5px',
                        marginLeft: '-1%',
                    }}> Settings </h2></div>

                    <div className="col-lg-3">
                        <ul className="nav nav-pills float-right" style={{marginBottom: '-5px'}}>
                            <li className="nav-item  active show">
                                <a className="nav-link" id="pills-home-tab" data-toggle="pill" href={"# "}
                                   role="tab"
                                   aria-controls="pills-home" aria-selected="true">Übersicht</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href={"# "}
                                   role="tab" aria-controls="pills-profile" aria-selected="false">Erweitert</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <hr/>
            </div>

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <strong className="card-title">Geräte</strong>
                    </div>
                    <div className="card-body">
                        {this.state.loaded ? (
                            this.state.menuStructure.items.sort((a, b) => a.order - b.order).map(item => <div
                                currentId={this.state.deviceId} data={item}
                                key={item.order}> {item.typ} {item.title} {item.deviceId}</div>)
                        ) : (<div>loading..</div>)}


                    </div>
                </div>
            </div>
            <Link
                to={"./erweitert"}>
                <button type="button" className="btn btn-primary">Mehr Daten…</button>
            </Link>
            <style media="screen"
                   dangerouslySetInnerHTML={{__html: "\n        /* Dropdown Button */\n        .dropbtn {\n            background-color: #3498DB;\n            color: white;\n            padding: 6px;\n            font-size: 15px;\n            border: none;\n            cursor: pointer;\n            width: 190px;\n        }\n\n        /* Dropdown button on hover & focus */\n        .dropbtn:hover, .dropbtn:focus {\n            background-color: #2774a8;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);\n\n        }\n\n        /* The container <div> - needed to position the dropdown content */\n        .dropdown {\n            position: relative;\n            display: inline-block;\n        }\n\n        /* Dropdown Content (Hidden by Default) */\n        .dropdown-content {\n            display: none;\n            padding: 13px;\n            position: absolute;\n            background-color: #f1f1f1;\n            width: 50vh;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n            z-index: 1;\n            right: 0%;\n        }\n\n        /* Links inside the dropdown */\n        .dropdown-content a {\n            color: black;\n            padding: 12px 16px;\n            text-decoration: none;\n            display: block;\n        }\n\n        /* Change color of dropdown links on hover */\n        .dropdown-content a:hover {\n            background-color: #ddd\n        }\n\n        /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */\n        .show {\n            display: block;\n        }\n    "}}/>
        </div>);
    }
}

export default Settings;
