/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
    }
  }
`;
export const createMenuItem = /* GraphQL */ `
  mutation CreateMenuItem(
    $input: CreateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    createMenuItem(input: $input, condition: $condition) {
      id
      title
      cognitoId
      deviceId
      color
      imagePath
      logoPath
      typ
      installationId
      order
    }
  }
`;
export const updateMenuItem = /* GraphQL */ `
  mutation UpdateMenuItem(
    $input: UpdateMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    updateMenuItem(input: $input, condition: $condition) {
      id
      title
      cognitoId
      deviceId
      color
      imagePath
      logoPath
      typ
      installationId
      order
    }
  }
`;
export const deleteMenuItem = /* GraphQL */ `
  mutation DeleteMenuItem(
    $input: DeleteMenuItemInput!
    $condition: ModelMenuItemConditionInput
  ) {
    deleteMenuItem(input: $input, condition: $condition) {
      id
      title
      cognitoId
      deviceId
      color
      imagePath
      logoPath
      typ
      installationId
      order
    }
  }
`;
export const createGriddata = /* GraphQL */ `
  mutation CreateGriddata(
    $input: CreateGriddataInput!
    $condition: ModelGriddataConditionInput
  ) {
    createGriddata(input: $input, condition: $condition) {
      id
      device
      i
      x
      y
      w
      h
      isResizable
      minW
      maxW
      minH
      maxH
      ownId
      title
      chartAxes {
        items {
          id
          sid
          GriddataId
          name
          yAxis
          visible
          colum
          color
          table
          withoutresolution
          standardInterval
        }
        nextToken
      }
    }
  }
`;
export const updateGriddata = /* GraphQL */ `
  mutation UpdateGriddata(
    $input: UpdateGriddataInput!
    $condition: ModelGriddataConditionInput
  ) {
    updateGriddata(input: $input, condition: $condition) {
      id
      device
      i
      x
      y
      w
      h
      isResizable
      minW
      maxW
      minH
      maxH
      ownId
      title
      chartAxes {
        items {
          id
          sid
          GriddataId
          name
          yAxis
          visible
          colum
          color
          table
          withoutresolution
          standardInterval
        }
        nextToken
      }
    }
  }
`;
export const deleteGriddata = /* GraphQL */ `
  mutation DeleteGriddata(
    $input: DeleteGriddataInput!
    $condition: ModelGriddataConditionInput
  ) {
    deleteGriddata(input: $input, condition: $condition) {
      id
      device
      i
      x
      y
      w
      h
      isResizable
      minW
      maxW
      minH
      maxH
      ownId
      title
      chartAxes {
        items {
          id
          sid
          GriddataId
          name
          yAxis
          visible
          colum
          color
          table
          withoutresolution
          standardInterval
        }
        nextToken
      }
    }
  }
`;
export const createAxes = /* GraphQL */ `
  mutation CreateAxes(
    $input: CreateAxesInput!
    $condition: ModelAxesConditionInput
  ) {
    createAxes(input: $input, condition: $condition) {
      id
      sid
      GriddataId
      name
      yAxis
      visible
      colum
      color
      table
      withoutresolution
      standardInterval
    }
  }
`;
export const updateAxes = /* GraphQL */ `
  mutation UpdateAxes(
    $input: UpdateAxesInput!
    $condition: ModelAxesConditionInput
  ) {
    updateAxes(input: $input, condition: $condition) {
      id
      sid
      GriddataId
      name
      yAxis
      visible
      colum
      color
      table
      withoutresolution
      standardInterval
    }
  }
`;
export const deleteAxes = /* GraphQL */ `
  mutation DeleteAxes(
    $input: DeleteAxesInput!
    $condition: ModelAxesConditionInput
  ) {
    deleteAxes(input: $input, condition: $condition) {
      id
      sid
      GriddataId
      name
      yAxis
      visible
      colum
      color
      table
      withoutresolution
      standardInterval
    }
  }
`;
export const createStartGraphic = /* GraphQL */ `
  mutation CreateStartGraphic(
    $input: CreateStartGraphicInput!
    $condition: ModelStartGraphicConditionInput
  ) {
    createStartGraphic(input: $input, condition: $condition) {
      id
      cognitoId
      typ
      width
      height
      left
      top
      imagepath
      imagealttag
      deviceID
      valueName
      valueUnit
      valueAdd
      valueIdentifierName
    }
  }
`;
export const updateStartGraphic = /* GraphQL */ `
  mutation UpdateStartGraphic(
    $input: UpdateStartGraphicInput!
    $condition: ModelStartGraphicConditionInput
  ) {
    updateStartGraphic(input: $input, condition: $condition) {
      id
      cognitoId
      typ
      width
      height
      left
      top
      imagepath
      imagealttag
      deviceID
      valueName
      valueUnit
      valueAdd
      valueIdentifierName
    }
  }
`;
export const deleteStartGraphic = /* GraphQL */ `
  mutation DeleteStartGraphic(
    $input: DeleteStartGraphicInput!
    $condition: ModelStartGraphicConditionInput
  ) {
    deleteStartGraphic(input: $input, condition: $condition) {
      id
      cognitoId
      typ
      width
      height
      left
      top
      imagepath
      imagealttag
      deviceID
      valueName
      valueUnit
      valueAdd
      valueIdentifierName
    }
  }
`;
