import React, {Component} from "react";
import GridLayout from 'react-grid-layout';
import '../../node_modules/react-grid-layout/css/styles.css'
import Highcharts from "highcharts";
import Boost from "highcharts/modules/boost";
import * as PropTypes from "prop-types";
import Chart from "./Chart";
import ChartOption from "./ChartOption";
import * as customQueries from "../graphql/customqueries"
import {API, graphqlOperation} from "aws-amplify";
import * as Mutations from "../graphql/mutations";
import {Link} from "react-router-dom";

Chart.propTypes = {
    options: PropTypes.any,
    ref: PropTypes.any
};

class ChartContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            axes: this.props.axes.items,
            title: this.props.title,
            cangeCount: 0,
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.interval !== "" || this.state.axes === nextState.axes
    }

    changeCheck(id) {
        const chart = this.state.axes;
        chart[id].visible = !chart[id].visible;
        this.setState({
            axes: chart
        });
        this.setState({
            cangeCount: this.state.cangeCount + 1
        })
    }

    render() {
        return <div className="card" style={{height: "100%"}}>
            <div className="card-header draggable" style={{cursor: "move"}}>
                <strong className="card-title">{this.state.title}</strong>

                <div className="  float-right notDraggable " style={{margin: "0"}}>
                    {this.state.axes.map(option => <div style={{display: 'inline-block'}}
                                                        onClick={() => this.changeCheck(option.sid)} key={option.sid}>
                        <ChartOption title={option.name} color={option.color}/></div>)}
                </div>
            </div>
            <div className="card-body" style={{height: "100%", width: "100%", display: "inline"}}>
                <Chart options={this.props.options} divice={this.props.divice} diviceId={this.props.diviceId}
                       layout={this.props.layout} ownId={this.props.ownId} interval={this.props.interval}
                       axes={this.state.axes}
                       cangeCount={this.state.cangeCount}/>
            </div>
        </div>;
    }
}

ChartContainer.propTypes = {
    options: PropTypes.any,
    ref: PropTypes.any,
    divice: PropTypes.string,
    layout: PropTypes.arrayOf(PropTypes.any),
    interval: PropTypes.any
};

class CGET340Expanded extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // To avoid unnecessary update keep all options in the state.
            hoverData: null,
            lastDevice: null,
            intervalName: "Heute",
            interval: "",
            timeDropdownOpend: false,
            graphData: {},
            itsLoading: false,
            currentLayout: []
        };
        Boost(Highcharts);


        Highcharts.setOptions(
            {
                global: {
                    useUTC: false
                }
            });
    }

    getTitle(id) {
        switch (parseInt(id)) {
            case 1:
                return "Netzanschluss";
            case 2:
                return "";
            case 3:
                return "PV-Anlage";
            case 4:
                return "Hausverbrauch";
            case 5:
                return "Ladestation Elektroauto";
            case 6:
                return "Waschmaschine";
            case 7:
                return "ereneo sun storage";
            default:
                return "-";
        }
    }

    onChange = async (layout) => {
        console.log("test");
        let output = [];
        this.state.currentLayout.map((item, i) => output.push({...item, ...layout[i]}));
        let input = {};
        for (let item of output) {
            input.x = item.x;
            input.y = item.y;
            input.w = item.w;
            input.h = item.h;
            input.id = item.id;
            await API.graphql(graphqlOperation(Mutations.updateGriddata, {input: input}));
        }
    };


    cangeIntervall = (interval, label) => {
        this.setState({intervalName: label, timeDropdownOpend: false, interval: interval});
    };

    async getData() {
        const allTodos = await API.graphql(graphqlOperation(customQueries.listGriddatac, {filter: {device: {ge: "1111/CGET340/1"}}}));
        this.setState({currentLayout: allTodos.data.listGriddatas.items, itsLoading: false});
        console.log(this.state.currentLayout)
    }

    render() {

        const currentDevice = this.props.installationId + '/' + this.props.typID + '/' + this.props.deviceID;
        if (currentDevice !== this.state.lastDevice) {
            this.setState({lastDevice: currentDevice});

            fetch('https://api.ereneo.com/V1/api_get_milestone_data?name=' + this.props.installationId + '/' + this.props.typID + '/' + this.props.deviceID)
                .then(res => res.json())
                .then((data) => {
                    this.setState({milestoneData: data});
                })
                .catch(console.log);
            this.getData()
        }

        return (<div className="content mt-3" style={{padding: '1% 3% 1% 3%'}}>
            <div style={{padding: '0% 1% 0% 1%'}}>
                <div style={{
                    fontFamily: '"Roboto", serif',
                    marginBottom: '0px',
                    marginTop: '-10px',
                }}>
                    <div className="col-lg-9"><h2 style={{
                        fontFamily: '"Roboto", serif',
                        marginBottom: '-5px',
                        marginLeft: '-1%',
                    }}>{this.getTitle(this.props.deviceID)} - Übersicht </h2></div>
                    <div className="col-lg-3">
                        <ul className="nav nav-pills float-right" style={{marginBottom: '-5px'}}>
                            <Link
                                to={"./"}>
                                <li className="nav-item  ">

                                    <div className="nav-link" id="pills-home-tab" data-toggle="pill"
                                         role="tab"
                                         aria-controls="pills-home" aria-selected="true">Übersicht
                                    </div>
                                </li>
                            </Link>
                            <Link
                                to={"./erweitert"}>
                                <li className="nav-item active show">

                                    <div className="nav-link" id="pills-profile-tab" data-toggle="pill"
                                         role="tab" aria-controls="pills-profile" aria-selected="false">Erweitert
                                    </div>
                                </li>
                            </Link>
                        </ul>
                    </div>

                </div>

            </div>
            <div className="col-lg-12">
                <hr/>
            </div>
            <div className="col-lg-12" style={{paddingBottom: 12}}>
                <div className="dropdown float-left">
                    <button onClick={() => this.setState({timeDropdownOpend: !this.state.timeDropdownOpend})}
                            className="dropbtn">{this.state.intervalName}</button>
                    <div id="myDropdown"
                         className={"dropdown-content" + (this.state.timeDropdownOpend ? ' show' : '')}>
                        <div className="row">
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "1h", "1 Stunde")}>
                                    <div className=" text-secondary">Letzte 1 Stunde</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "1w", "Diese Woche")}>
                                    <div className=" text-secondary">Diese Woche</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "today", "Heute")}>
                                    <div className=" text-secondary">Heute</div>
                                </a>
                                {/* first line */}
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "3h", "Letzte 3 Stunde")}>
                                    <div className=" text-secondary">Letzte 3 Stunde</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "1m", "Dieser Monat")}>
                                    <div className=" text-secondary">Dieser Monat</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "yesterday", "Gestern")}>
                                    <div className=" text-secondary">Gestern</div>
                                </a>
                            </div>
                            {/* second line */}
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "6h", "Letzte 6 Stunde")}>
                                    <div className=" text-secondary">Letzte 6 Stunde</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "1j", "Dieses Jahr")}>
                                    <div className=" text-secondary">Dieses Jahr</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "daybeforeyesterday", "Vorgestern")}>
                                    <div className=" text-secondary">Vorgestern</div>
                                </a>
                            </div>
                            {/* fourth line */}
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "12h", "Letzten 12 Stunde")}>

                                    <div className="text-secondary">Letzten 12 Stunde</div>
                                </a>
                            </div>
                            <div className="col-lg-4">
                                <a className="card" style={{cursor: "pointer"}} href={"# "}
                                   onClick={this.cangeIntervall.bind(this, "live", "Live Data")}>

                                    <div className="text-secondary">Live Data</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!this.state.isLoading ? (

                <GridLayout className="col-lg-12" layout={this.state.currentLayout} cols={3} width={1400} rowHeight={35}
                            margin={[10, 10]}
                            {...this.state.isLoading}
                            containerPadding={[0, 0]} onLayoutChange={this.onChange}
                            draggableHandle={".draggable"}
                            draggableCancel={".notDraggable"}
                            breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                            onResizeStop={function (event) {
                                let output = [];
                                this.state.currentLayout.map((item, i) => output.push({...item, ...event[i]}));
                                this.setState({currentLayout: output})
                            }.bind(this)}
                >


                    {this.state.currentLayout !== undefined ? (this.state.currentLayout.map(x => (
                        <div key={x.i}>
                            <ChartContainer
                                divice={currentDevice}
                                ownId={x.ownId}
                                title={x.title}
                                axes={x.chartAxes}
                                diviceId={this.props.deviceID}
                                layout={this.state.currentLayout} interval={this.state.interval}/>
                        </div>
                    ))) : (<div>test</div>)}</GridLayout>) : (<div>restr</div>)
            }
            <style media="screen"
                   dangerouslySetInnerHTML={{__html: "\n        /* Dropdown Button */\n        .dropbtn {\n            background-color: #3498DB;\n            color: white;\n            padding: 6px;\n            font-size: 15px;\n            border: none;\n            cursor: pointer;\n            width: 190px;\n        }\n\n        /* Dropdown button on hover & focus */\n        .dropbtn:hover, .dropbtn:focus {\n            background-color: #2774a8;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);\n\n        }\n\n        /* The container <div> - needed to position the dropdown content */\n        .dropdown {\n            position: relative;\n            display: inline-block;\n        }\n\n        /* Dropdown Content (Hidden by Default) */\n        .dropdown-content {\n            display: none;\n            padding: 13px;\n            position: absolute;\n            background-color: #f1f1f1;\n            width: 50vh;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n            z-index: 1;\n            left: 0;\n        }\n\n        /* Links inside the dropdown */\n        .dropdown-content a {\n            color: black;\n            padding: 12px 16px;\n            text-decoration: none;\n            display: block;\n        }\n\n        /* Change color of dropdown links on hover */\n        .dropdown-content a:hover {\n            background-color: #ddd\n        }\n\n        /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */\n        .show {\n            display: block;\n        }\n    "}}/>
        </div>);
    }
}

export default CGET340Expanded;