// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:80f187cf-e702-49fe-9d96-7f90294f22f3",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_LgAW5EjUL",
    "aws_user_pools_web_client_id": "4an4avkp8h2d0op3gege2sbv3d",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://g7s2veirdvexvaqlr73wsepd2y.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://mvx546zj53.execute-api.eu-central-1.amazonaws.com/lukas",
            "region": "eu-central-1"
        }
    ],
    "aws_mobile_analytics_app_id": "8f57d31cd5a047b299c4b60c553c21a2",
    "aws_mobile_analytics_app_region": "eu-central-1"
};


export default awsmobile;
