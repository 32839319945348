import React from "react";
import $ from 'jquery';
import {API, Auth, graphqlOperation} from "aws-amplify";
import * as customQueries from "../graphql/queries";
import LiveData from "./LiveData";

class StartPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            lastDevice: "",
            cognitoId: 0,
            startGraphic: null,
            balls: [{name: "test"}, {name: "1123"}]
        };
    }

    AnimateBobel(name, startTop, startLeft,
                 stopTop, stopLeft, BetweenTop, BetweenLeft, orderOne, orderTowe) {
        let id = Math.floor((Math.random() * 100) + 1);
        $(".box").append(` <div class='image' style='left: 1020px;top: 405px; z-index: 5;' id='bole` + id + `'><img src="https://portal.ereneo.de/images/dashboard/svg-27148x27.svg" alt="boble" style="  width: 10px; "/></div>`);
        name = "#bole" + id;
        let speed = 20;
        $(name).animate({top: startTop, left: startLeft,},
            0, "linear", function () {
            });
        if (orderOne === 1) {
            $(name).animate({top: BetweenTop}, Math.abs(BetweenTop - startTop) * speed, 'linear');
            $(name).animate({left: BetweenLeft}, Math.abs(BetweenLeft - startLeft) * speed, 'linear');
        } else {
            $(name).animate({left: BetweenLeft}, Math.abs(BetweenLeft - startLeft) * speed, 'linear');
            $(name).animate({top: BetweenTop}, Math.abs(BetweenTop - startTop) * speed, 'linear');
        }
        if (orderTowe === 1) {
            $(name).animate({left: stopLeft}, Math.abs(stopLeft - BetweenLeft) * speed, 'linear');
            $(name).animate({top: stopTop}, Math.abs(stopTop - BetweenTop) * speed, 'linear', function () {
                $(name).remove();
            });
        } else {
            $(name).animate({top: stopTop}, Math.abs(stopTop - BetweenTop) * speed, 'linear');
            $(name).animate({left: stopLeft}, Math.abs(stopLeft - BetweenLeft) * speed, 'linear', function () {
                $(name).remove();
            });
        }
    }

    componentDidMount() {

        this.interval = setInterval(() => {
            // let sun_wert = <LiveData  device={"currentDevice"} deviceID={1} name={"kWL1"} decimals={1} add={["kWL2", "kWL3"]} unit={""}/>;
            // let monitoring_wert_1 = <LiveData  device={"currentDevice"} deviceID={4} name={"kWL1"} decimals={1} add={["kWL2", "kWL3"]} unit={""}/>;
            // let monitoring_wert_2 = <LiveData  device={"currentDevice"} deviceID={5} name={"kWL1"} decimals={1} add={["kWL2", "kWL3"]} unit={""}/>;
            // let monitoring_wert_3 = <LiveData  device={"currentDevice"} deviceID={6} name={"kWL1"} decimals={1} add={["kWL2", "kWL3"]} unit={""}/>;
            // let storage_wert = <LiveData  device={"currentDevice"} deviceID={7} name={"kWL1"} decimals={1} add={["kWL2", "kWL3"]} unit={""}/>;
            // let grid_wert = <LiveData  device={"currentDevice"} deviceID={1} name={"kWL1"} decimals={1} add={["kWL2", "kWL3"]} unit={""}/>;

            if ($('.sun_wert').html().replace(' ', '').replace('W', '') < 10) {
                if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 168, 636, 259, 1050, 259, 1050, 1, 1);
                }
                if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 168, 636, 344, 1050, 259, 836, 1, 0);
                }
                if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 168, 636, 432, 1050, 259, 836, 1, 0);
                }
                if ($('.storage_wert_2').html().replace(' ', '').replace('W', '') > 0) {
                    this.AnimateBobel("#1", 168, 636, 370, 356, 259, 356, 1, 1);
                }
                if ($('.grid_wert').html().replace(' ', '').replace('W', '') < -40) {
                    this.AnimateBobel("#1", 168, 636, 259, 120, 259, 120, 1, 1);
                }
            }

            if ($('.grid_wert').html().replace(' ', '').replace('W', '') > 50) {
                if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 259, 120, 259, 1050, 259, 1050, 1, 1);
                }
                if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 259, 120, 344, 1050, 259, 836, 1, 0);
                }
                if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 259, 120, 432, 1050, 259, 836, 1, 0);
                }
            }
            if ($('.storage_wert_2').html().replace(' ', '').replace('W', '') < 10) {
                if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 370, 356, 259, 1050, 259, 1050, 1, 1);
                }
                if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 370, 356, 344, 1050, 259, 836, 1, 0);
                }
                if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 20) {
                    this.AnimateBobel("#1", 370, 356, 432, 1050, 259, 836, 1, 0);
                }

            }


            // setTimeout(() => {
            //
            //
            //         if ($('.sun_wert').html() < 500) {
            //             if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 259, 1050, 259, 1050, 1, 1);
            //             }
            //             if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 353, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 432, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.storage_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 370, 356, 259, 356, 1, 1);
            //             }
            //             if ($('.grid_wert').html().replace(' ', '').replace('W', '') < -200) {
            //                 this.AnimateBobel("#1", 168, 536, 259, 120, 259, 120, 1, 1);
            //             }
            //
            //         }
            //
            //         if ($('.grid_wert').html().replace(' ', '').replace('W', '') > 500) {
            //             if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 259, 120, 259, 1050, 259, 1050, 1, 1);
            //             }
            //             if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 259, 120, 353, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 259, 120, 432, 1050, 259, 836, 1, 0);
            //             }
            //         }
            //         if ($('.storage_wert_2').html().replace(' ', '').replace('W', '') < 500) {
            //             if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 370, 356, 259, 1050, 259, 1050, 1, 1);
            //             }
            //             if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 370, 356, 353, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 370, 356, 432, 1050, 259, 836, 1, 0);
            //             }
            //
            //         }
            //
            //
            //
            //
            //
            // }, 2000)


        }, 5000);
        Auth.currentSession().then(data => this.setState({cognitoId: data.idToken.payload.sub})).catch(err => console.log(err))

    }

    async getData() {
        const allTodos = await API.graphql(graphqlOperation(customQueries.listStartGraphics, {
            cognitoId: this.state.cognitoIdm,
            limit: 100
        }));
        this.setState({startGraphic: allTodos.data.listStartGraphics});
        this.setState({loaded: true});
    }


    componentWillUnmount() {
        clearInterval(this.interval)
    }

    render() {


        if (this.state.loaded !== true) {
            this.getData()
        }
        return (<div className="content" style={{padding: '1% 3% 1% 3%'}}>
                <div className="col-lg-12">
                    <h2 style={{fontFamily: '"Roboto", serif', marginBottom: '-14px'}}> Überblick </h2>
                    <hr/>
                    <div className="card" id="mainpart">
                        <div id="opend" className="card-header">
                            <strong className="card-title"> <span className="float-right mt-2"><a href={"# "}
                            >
                        <span className="ti-fullscreen"/>
                      </a></span><a href={"# "}>
                            </a>
                            </strong>
                        </div>
                        <div className="card-body">
                            <link href="https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap"
                                  rel="stylesheet"/>
                            <div className="box">


                                {this.state.loaded ? (
                                    this.state.startGraphic.items.map(item =>
                                        (item.typ === "image") ? (<div className={"image"} style={{
                                            left: item.left + 'px',
                                            top: item.top + 'px'
                                        }}>
                                            <img src={item.imagepath}
                                                 style={{width: item.width + 'px'}} alt={"img1"}/>
                                        </div>) : ((item.typ === "line") ? (
                                                <div className="line" style={{
                                                    width: item.width + 'px',
                                                    height: item.height + 'px',
                                                    left: item.left + 'px',
                                                    top: item.top + 'px'
                                                }}/>) : ((item.typ === "valuebox") ? (
                                                    <div className={"valuebox " + item.valueIdentifierName}
                                                         style={{left: item.left + 'px', top: item.top + 'px'}}>
                                                        <LiveData device={"currentDevice"} deviceID={item.deviceID}
                                                                  name={item.valueName}
                                                                  decimals={1} add={item.valueAdd.split("+")}
                                                                  unit={item.valueUnit}/>
                                                    </div>
                                                ) : (<></>)
                                            )
                                        )
                                    )
                                ) : (<div>loading..</div>)}


                                {/*/!*  Haupt strich   *!/*/}
                                {/*<div className="line"*/}
                                {/*     style={{width: '850px', height: '3px', left: '160px', top: '272px'}}/>*/}
                                {/*/!*  Netz Icon   *!/*/}
                                {/*<div className="image" style={{left: '30px', top: '212px'}}>*/}
                                {/*    <img src="https://portal.ereneo.de/images/dashboard/svg-135055x133.svg"*/}
                                {/*         style={{width: '110px'}} alt={"img1"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Value Box Netz   *!/*/}
                                {/*<div className="valuebox grid_wert" style={{left: '180px', top: '257px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={1} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}

                                {/*</div>*/}
                                {/*/!*  Strich zum Speicher   *!/*/}
                                {/*<div className="line"*/}
                                {/*     style={{width: '3px', height: '86px', left: '360px', top: '272px'}}/>*/}
                                {/*/!*  Speicher Icon   *!/*/}
                                {/*<div className="image" style={{left: '290px', top: '362px'}}>*/}
                                {/*    <img src="https://portal.ereneo.de/images/dashboard/svg-127556x60.svg"*/}
                                {/*         style={{width: '150px'}} alt={"img2"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Value Box Speicher  *!/*/}
                                {/*<div className="valuebox" style={{left: '420px', top: '258px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={2} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}
                                {/*</div>*/}
                                {/*  Value Box Speicher  */}
                                {/*<div className="valuebox storage_wert_2" style={{left: '300px', top: '312px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={7} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Strich zur Solar   *!/*/}
                                {/*<div className="line"*/}
                                {/*     style={{width: '3px', height: '106px', left: '640px', top: '166px'}}/>*/}
                                {/*/!*  Solar Icon   *!/*/}
                                {/*<div className="image" style={{left: '540px', top: '122px'}}>*/}
                                {/*    <img src="https://portal.ereneo.de/images/dashboard/svg-25394x43.svg"*/}
                                {/*         style={{width: '210px'}} alt={"img3"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Value Box Solar  *!/*/}
                                {/*<div className="valuebox sun_wert" style={{left: '580px', top: '225px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={3} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Wechselrichter Icon   *!/*/}
                                {/*<div className="image" style={{left: '627px', top: '182px'}}>*/}
                                {/*    <img*/}
                                {/*        src="https://portal.ereneo.de/images/dashboard/svg-153x153-eingefuegt.svg"*/}
                                {/*        style={{width: '30px'}} alt={"img4"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Hilfs Strich 1   *!/*/}
                                {/*<div className="line"*/}
                                {/*     style={{width: '3px', height: '176px', left: '840px', top: '272px'}}/>*/}
                                {/*/!*  Strich zu Auto   *!/*/}
                                {/*<div className="line"*/}
                                {/*     style={{width: '170px', height: '3px', left: '840px', top: '357px'}}/>*/}
                                {/*/!*  Strich zu Waschmaschine   *!/*/}
                                {/*<div className="line"*/}
                                {/*     style={{width: '170px', height: '3px', left: '840px', top: '445px'}}/>*/}
                                {/*/!*  Value Box Haus  *!/*/}
                                {/*<div className="valuebox monitoring_wert_1" style={{left: '860px', top: '258px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={4} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Value Box Auto  *!/*/}
                                {/*<div className="valuebox monitoring_wert_2" style={{left: '860px', top: '342px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={6} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Value Box Waschmaschine  *!/*/}
                                {/*<div className="valuebox monitoring_wert_3" style={{left: '860px', top: '430px'}}>*/}
                                {/*    <LiveData device={"currentDevice"} deviceID={5} name={"kWL1"}*/}
                                {/*              decimals={1} add={["kWL2", "kWL3"]} unit={"W"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Haus Icon   *!/*/}
                                {/*<div className="image" style={{left: '1020px', top: '212px'}}>*/}
                                {/*    <img src="https://portal.ereneo.de/images/dashboard/svg-113983x82.svg"*/}
                                {/*         style={{width: '110px'}} alt={"img5"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Auto Icon   *!/*/}
                                {/*<div className="image" style={{left: '1020px', top: '332px'}}>*/}
                                {/*    <img src="https://portal.ereneo.de/images/dashboard/svg-169112x70.svg"*/}
                                {/*         style={{width: '120px'}} alt={"img6"}/>*/}
                                {/*</div>*/}
                                {/*/!*  Waschmaschine Icon   *!/*/}
                                {/*<div className="image" style={{left: '1020px', top: '405px'}}>*/}
                                {/*    <img src="https://portal.ereneo.de/images/dashboard/svg-40x45.svg"*/}
                                {/*         style={{width: '70px'}} alt={"img7"}/>*/}
                                {/*</div>*/}
                                {/*
    <div class="image" style="left: 1020px;top: 405px;" id="2">
        <img src="https://portal.ereneo.de/images/dashboard/svg-27148x27.svg" style="  width: 10px; ">
    </div>

    <div class="image" style="left: 1020px;top: 405px;" id="1">
        <img src="https://portal.ereneo.de/images/dashboard/svg-168891x97.svg" style="  width: 150px; ">
    </div>

*/}
                            </div>

                            <style
                                dangerouslySetInnerHTML={{__html: "\n\n        .close {\n            position: absolute;\n            right: 32px;\n            top: 32px;\n            width: 20px;\n            height: 20px;\n            opacity: 0.3;\n        }\n        .close:hover {\n            opacity: 1;\n        }\n        .close:before, .close:after {\n            position: absolute;\n            left: 15px;\n            content: ' ';\n            height: 33px;\n            width: 2px;\n            background-color: #333;\n        }\n        .close:before {\n            transform: rotate(45deg);\n        }\n        .close:after {\n            transform: rotate(-45deg);\n        }\n\n\n        .box {\n        background-color: white;\n        width: 1200px;\n        height: 550px;\n        position: relative;\n\n\n    }\n\n    .part1 {\n        position: absolute;\n        left: 0px;\n        top: 0px;\n        background-color: blanchedalmond;\n        width: 30px;\n        height: 30px;\n        margin: 0;\n    }\n\n    .part2 {\n        position: absolute;\n        left: 30px;\n        top: 10px;\n        background-color: blanchedalmond;\n        width: 30px;\n        height: 30px;\n    }\n\n    .image {\n        position: absolute;\n        z-index: 10;\n    }\n\n    .line {\n        position: absolute;\n       background-color: black;\n    }\n\n    .valuebox {\n        z-index: 11;\n box-shadow:        -1px 0px 18px 0px rgba(0, 0, 0, 0.1);\n      position: absolute;\n        text-align: center;\n        background-color: #b9da8f;\n        color: white;\n        width: 120px;\n        border-radius: 20px;\n        padding-top: 5px;\n        padding-bottom: 5px;\n        font-family: 'Roboto', serif;\n        font-weight:300;\n } "}}/>
                        </div>
                    </div>


                </div>
                <div className="col-lg-12">

                </div>
            </div>
        );
    }
}

export default StartPage;


// function GuessButton(name, startTop, startLeft, stopTop, stopLeft, BetweenTop, BetweenLeft, orderOne, orderTowe) {
//
//
//     let left = [];
//     let top = [];
//     let times = [-0.1];
//     left.push(startLeft + "px");
//     top.push(startTop + "px");
//     if (orderOne === 1) {
//         top.push(BetweenTop + "px");
//         left.push(startLeft + "px");
//         left.push(BetweenLeft + "px");
//         top.push(BetweenTop + "px");
//         //times.push(Math.abs(stopLeft - BetweenTop )/300 );
//
//     } else {
//         top.push(startTop + "px");
//         left.push(BetweenLeft + "px");
//         left.push(BetweenLeft + "px");
//         top.push(BetweenTop + "px");
//     }
//     if (orderTowe === 1) {
//         top.push(BetweenTop + "px");
//         left.push(stopLeft + "px");
//         //left.push(stopLeft+"px");
//         //top.push(stopTop+"px");
//
//     } else {
//         top.push(stopTop + "px");
//         left.push(BetweenLeft + "px");
//         //left.push(stopLeft+"px");
//         //top.push(stopTop+"px");
//
//
//     }
//
//     left.push(stopLeft + "px");
//     top.push(stopTop + "px");
//     let i = 1;
//     let oldeValue = 0;
//     for (i = 0; i < top.length; i++) {
//         i++;
//         console.log(Math.round((Math.abs((parseInt(left[i]) + parseInt(top[i])) - oldeValue) / 2000) * 10) / 10);
//         times.push(Math.round((Math.abs((parseInt(left[i]) + parseInt(top[i])) - oldeValue) / 2000 + times[times.length - 1]) * 10) / 10);
//         oldeValue = parseInt(left[i]) - parseInt(top[i]);
//
//     }
//
//     times[0] = 0.1;
//     times = times.splice(0, times.length - 1);
//
//     return (
//         <motion.div
//             animate={{
//                 left: left,
//                 top: top,
//             }}
//             transition={{
//                 duration: 10,
//                 times: times,
//                 loop: 0,
//             }}
//             style={{
//                 background: "black",
//                 width: "10px",
//                 height: "10px",
//                 position: "absolute"
//             }
//
//             }
//         />
//     );
// }

