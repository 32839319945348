import React from 'react'
import {DragSource} from 'react-dnd'
import LiveData from "../LiveData";

const style = {
    position: 'absolute',
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    cursor: 'move',
}
const Box = ({
                 hideSourceOnDrag,
                 left,
                 top,
                 connectDragSource,
                 isDragging,
                 children,
                 item,
             }) => {
    if (isDragging && hideSourceOnDrag) {
        return null
    }
    return connectDragSource(
        (item.typ === "image") ? (
            <div className={"image"} style={{
                left: item.left + 'px',
                top: item.top + 'px'
            }}>
                <img src={item.imagepath}
                     style={{width: item.width + 'px'}} alt={"img1"}/>
            </div>) : ((item.typ === "line") ? (
                <div className="line" style={{
                    width: item.width + 'px',
                    height: item.height + 'px',
                    left: item.left + 'px',
                    top: item.top + 'px'
                }}/>) : ((item.typ === "valuebox") ? (
                    <div className={"valuebox " + item.valueIdentifierName}
                         style={{left: item.left + 'px', top: item.top + 'px'}}>
                        <LiveData device={"currentDevice"} deviceID={item.deviceID}
                                  name={item.valueName}
                                  decimals={1} add={item.valueAdd.split("+")}
                                  unit={item.valueUnit}/>
                    </div>
                ) : (<></>)
            )
        )
    )
}
export default DragSource(
    'box',
    {
        beginDrag(props) {
            const {id, left, top} = props
            return {id, left, top}
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }),
)(Box)
