import React, {Component} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import {store} from "../App";


class Chart extends Component {
    constructor(props) {
        super(props);
        this.chartComponent = React.createRef();

        this.state = {
            chartOptions: {

                chart: {
                    displayErrors: true,
                    zoomType: 'x',
                    events: {
                        load() {
                            this.showLoading();
                        }


                    }
                },
                title: {
                    text: ''
                },

                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    },

                },
                tooltip: {
                    valueSuffix: ''
                },
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value} W',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: 'Leistung',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true

                }, { // Secondary yAxis
                    gridLineWidth: 0,
                    title: {
                        text: 'Energie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} kWh',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }

                }],
                legend: {
                    enabled: false
                },

                plotOptions: {
                    area: {
                        fillColor: {
                            linearGradient: {
                                x1: 0,
                                y1: 0,
                                x2: 1,
                                y2: 1
                            },
                            stops: [
                                [0, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                                [1, Highcharts.getOptions().colors[2]],
                            ]
                        },
                        marker: {
                            radius: 1
                        },
                        lineWidth: 1,
                        states: {
                            hover: {
                                lineWidth: 1
                            }
                        },
                        threshold: 0
                    }
                },

                series: props.axes,

            },
            hoverData: null,
            lastDevice: null,
            interval: "24h",
            intervalName: "",
            timeDropdownOpend: false,
            graphData: {},
            itsLoading: false,
            axes: null,
            ownId: this.props.ownId,
            deviceId: parseInt(this.props.diviceId),

        };
        Boost(Highcharts);
        Highcharts.setOptions(
            {
                global: {
                    useUTC: false
                }

            });
    }

    componentDidMount() {
        this.setState({
            chartOptions: {
                series: this.props.axes
            }
        });
        const chart = this.chartComponent.current.chart;
        let shiftFlag = chart.series[0].data.length > 40;
        this.chartComponent.current.chart.reflow();
        this.axesOne = store.subscribe(() => chart.series[0].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, (Math.round((parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[0]["colum"]]) + parseFloat(store.getState().websocketData[this.state.deviceId]["kWL2"]) + parseFloat(store.getState().websocketData[this.state.deviceId]["kWL3"]) * 10) / 10))], true, shiftFlag));
        this.axesTow = store.subscribe(() => chart.series[1].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[1]["colum"]])], true, shiftFlag));
        this.axesthree = store.subscribe(() => chart.series[2].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId][this.state.chartOptions.series[2]["colum"]])], true, shiftFlag))
    }

    componentWillUnmount() {
        this.axesOne();
        this.axesTow();
        this.axesthree();
        this.setState(null);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let finalwidth;
        let finalheight;
        const width = this.props.layout[this.state.ownId]["w"];
        const height = this.props.layout[this.state.ownId]["h"];

        switch (width) {
            case 1:
                finalwidth = 410;
                break;
            case 2:
                finalwidth = 882;
                break;
            case 3:
                finalwidth = 1349;
                break;
            default:
                finalwidth = 410;
                break;
        }
        switch (height) {
            case 6:
                finalheight = 185;
                break;
            case 7:
                finalheight = 220;
                break;
            case 8:
                finalheight = 270;
                break;
            case 9:
                finalheight = 320;
                break;
            case 10:
                finalheight = 368;
                break;
            case 11:
                finalheight = 400;
                break;
            case 12:
                finalheight = 450;
                break;
            default:
                finalheight = 185;
                break;
        }
        this.chartComponent.current.chart.setSize(finalwidth, finalheight);
        if (this.props.axes !== this.state.axes) {
            this.setState({axes: this.props.axes});

            const chart = this.state.chartOptions;
            chart.series = this.props.axes;
            this.setState({
                chartOptions: chart
            });
            this.setState({itsLoading: true});
        }
        this.chartComponent.current.chart.reflow()
    }

    updateSeries = (data, axes) => {
        let chart = this.state.chartOptions.series;
        chart[axes].data = data;
        this.setState({
            chartOptions: {
                series: chart
            }
        });
    };


    updateData = (axes) => {
        if (this.state.chartOptions.series !== undefined) {
            const colum = this.state.chartOptions.series[axes].colum;
            let interval = "";
            if (this.props.interval === "") {
                interval = this.state.chartOptions.series[axes].standardInterval;
            } else {
                interval = this.props.interval;
            }
            const table = (this.state.chartOptions.series[axes].table === null) ? ("") : (this.state.chartOptions.series[axes].table);
            const withoutresolution = this.state.chartOptions.series[axes].withoutresolution;
            fetch('https://api.ereneo.com/V1/Rest_API_Test?name=' + this.props.divice + table + '&column=' + colum + '&interval=' + interval + '&withoutresolution=' + withoutresolution)
                .then(res => res.json())
                .then((data) => {
                    this.updateSeries(data, axes);
                    this.chartComponent.current.chart.hideLoading();
                })
                .catch(console.log);
        }
    };

    render() {

        if (this.props.divice !== this.state.lastDevice) {
            this.setState({lastDevice: this.props.divice});
            this.updateData(0);
            this.updateData(1);
            this.updateData(2);
            this.setState({intervalName: "Auswahl Zeitfenster", timeDropdownOpend: false});
        }
        if (this.props.interval !== this.state.interval) {
            this.setState({interval: this.props.interval});
            this.updateData(0);
            this.updateData(1);
            this.updateData(2);
            this.setState({intervalName: "Auswahl Zeitfenster", timeDropdownOpend: false});
        }
        return (
            <>
                <HighchartsReact
                    style={{display: "inline-block"}}
                    {...this.props}
                    highcharts={Highcharts}
                    options={this.state.chartOptions}
                    ref={this.chartComponent}
                />
            </>
        )
    }
}
export default Chart;