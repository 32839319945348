import React, {Component} from "react";
import {Link} from "react-router-dom";
import {API, Auth, graphqlOperation} from "aws-amplify";
import * as customQueries from "../graphql/queries"
import Logout from "./Logout";
import MenuItem from "./MenuItem";


class lefPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            cognitoId: 0,
            test: "",
            userSub: null,
            userGroups: [],
            menuStructure: null,
            deviceId: 0,
        };
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(data => this.setState({userGroups: data.signInUserSession.accessToken.payload["cognito:groups"]})).catch(err => console.log(err))
    }

    async getData() {
        const allTodos = await API.graphql(graphqlOperation(customQueries.listMenuItems, {cognitoId: this.state.cognitoId}));
        this.setState({menuStructure: allTodos.data.listMenuItems});
        this.setState({loaded: true});
    }

    render() {
        if (this.state.loaded !== true) {
            this.getData();
            console.log(this.state.test);
            console.log(this.state.userGroups);
            console.log(this.state.test.username);
            //console.log(this.state.test.signInUserSession.accessToken.payload["cognito:groups"]);
        }
        console.log(this.state.userGroups)
        return (<aside id="left-panel" className="left-panel">
            <nav className="navbar navbar-expand-sm navbar-default">
                <div className="navbar-header">
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#main-menu" aria-controls="main-menu" aria-expanded="false"
                            aria-label="Toggle navigation"><i className="fa fa-bars"/></button>
                    <Link to="/" className="navbar-brand" onClick={() => this.setState({deviceId: 100})}><img
                        src={"/images/logo.png"}
                        alt="Logo"/></Link></div>
                <div id="main-menu" className="main-menu collapse navbar-collapse">
                    <div className="col menuebox" style={{}}>

                        {
                            (this.state.userGroups.includes("admins")) ? (
                                <div style={{margin: "0px 0px 0px 0px", position: "relative"}}>
                                    <Link
                                        to={"/admin/"}
                                        onClick={this.props.onClick}>
                                        <div className="card text-white bg-flat-color-3" style={{
                                            width: "310px",
                                            height: "105px",
                                            background: "#a29bfe",
                                            boxShadow: ("-2px 10px 50px -7px #FFFFFF"),
                                        }}>
                                            <div className="card-body">
                                                <img className="card-left"
                                                     src={"this.props.data.imagePath"}
                                                     style={{width: "80px", height: "80px", marginTop: "-9px"}}
                                                     alt="netzicon"/>
                                                <div className="card-right float-right text-right">
                                                    <img
                                                        src={"this.props.data.logoPath"}
                                                        style={{
                                                            width: "190px",
                                                            marginRight: "-9px",
                                                            marginTop: "-15px"
                                                        }} alt="erenogridlogo"/>
                                                    <div className="mb-0 fw-r">

                                                    </div>
                                                </div>
                                                {/* /.card-right */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ) : (<></>)
                        }

                        {this.state.loaded ? (
                            this.state.menuStructure.items.sort((a, b) => a.order - b.order).map(item => <MenuItem
                                onClick={() => this.setState({deviceId: item.deviceId})}
                                currentId={this.state.deviceId} data={item} key={item.order}/>)
                        ) : (<div>loading..</div>)}
                    </div>

                </div>
                <Logout/>
            </nav>
        </aside>);
    }
}

export default lefPanel;