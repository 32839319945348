import React, {Component} from 'react';


class ChartOption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastDevice: null,
            interval: "24h",
            intervalName: "",
            timeDropdownOpend: false,
            graphData: {},
            itsLoading: false,
            ckeckt: true,
        };
    }

    changeCheck() {
        this.setState({ckeckt: !this.state.ckeckt})
    }

    render() {
        return (
            <>
                <div style={this.state.ckeckt ? ({
                    color: this.props.color,
                    cursor: 'pointer',
                    display: 'inline-block',
                    paddingLeft: '7px',
                    userSelect: 'none'
                }) : ({cursor: 'pointer', display: 'inline-block', paddingLeft: '7px', color: '#bdc3c7'})}
                     onClick={() => this.changeCheck()}><i
                    className="fa fa-signal"/> {this.props.title} {this.state.ckeckt}</div>
            </>
        )
    }
}

export default ChartOption;