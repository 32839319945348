import {combineReducers} from 'redux'
import {ADD_TODO, TOGGLE_TODO} from './actions'

function websocketData(state = [], action) {
    switch (action.type) {
        case ADD_TODO:
            return action.text;
        case TOGGLE_TODO:
            return state.map((todo, index) => {
                if (index === action.index) {
                    return Object.assign({}, todo, {
                        completed: !todo.completed
                    })
                }
                return todo
            });
        default:
            return state
    }
}

const todoApp = combineReducers({
    websocketData
});
export default todoApp