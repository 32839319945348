/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
      }
      nextToken
    }
  }
`;
export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      title
      cognitoId
      deviceId
      color
      imagePath
      logoPath
      typ
      installationId
      order
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        cognitoId
        deviceId
        color
        imagePath
        logoPath
        typ
        installationId
        order
      }
      nextToken
    }
  }
`;
export const getGriddata = /* GraphQL */ `
  query GetGriddata($id: ID!) {
    getGriddata(id: $id) {
      id
      device
      i
      x
      y
      w
      h
      isResizable
      minW
      maxW
      minH
      maxH
      ownId
      title
      chartAxes {
        items {
          id
          sid
          GriddataId
          name
          yAxis
          visible
          colum
          color
          table
          withoutresolution
          standardInterval
        }
        nextToken
      }
    }
  }
`;
export const listGriddatas = /* GraphQL */ `
  query ListGriddatas(
    $filter: ModelGriddataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGriddatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        device
        i
        x
        y
        w
        h
        isResizable
        minW
        maxW
        minH
        maxH
        ownId
        title
        chartAxes {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAxes = /* GraphQL */ `
  query GetAxes($id: ID!) {
    getAxes(id: $id) {
      id
      sid
      GriddataId
      name
      yAxis
      visible
      colum
      color
      table
      withoutresolution
      standardInterval
    }
  }
`;
export const listAxess = /* GraphQL */ `
  query ListAxess(
    $filter: ModelAxesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAxess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sid
        GriddataId
        name
        yAxis
        visible
        colum
        color
        table
        withoutresolution
        standardInterval
      }
      nextToken
    }
  }
`;
export const getStartGraphic = /* GraphQL */ `
  query GetStartGraphic($id: ID!) {
    getStartGraphic(id: $id) {
      id
      cognitoId
      typ
      width
      height
      left
      top
      imagepath
      imagealttag
      deviceID
      valueName
      valueUnit
      valueAdd
      valueIdentifierName
    }
  }
`;
export const listStartGraphics = /* GraphQL */ `
  query ListStartGraphics(
    $filter: ModelStartGraphicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStartGraphics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        typ
        width
        height
        left
        top
        imagepath
        imagealttag
        deviceID
        valueName
        valueUnit
        valueAdd
        valueIdentifierName
      }
      nextToken
    }
  }
`;
