import React, {Component} from 'react';
import {Auth} from 'aws-amplify';

class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userAttributes: ""
        }
    }

    componentDidMount() {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => this.setState({userAttributes: user.attributes}))
            .catch(err => console.log(err));


    }

    render() {
        return (
            <div style={{
                position: "fixed",
                left: 0,
                bottom: 0,
                width: "380px",
                height: "45px",
                backgroundColor: "#ffffff",
                padding: "10px",
                boxShadow: "-9px -11px 42px -13px rgba(255,255,255,0.36)"

            }}>
                <div style={{textAlign: "left"}} className={"text"}>
                    {this.state.userAttributes.email}
                </div>
                <div style={{textAlign: "right", marginTop: "-22px", cursor: "pointer"}}
                     onClick={() => (Auth.signOut().then(data => console.log(data)).catch(err => console.log(err)))}
                     href={"# "}>
                    <div className=" text-secondary"><img src={"/images/icons/logoutIcon.png"}
                                                          style={{width: "21px", marginTop: "-7px"}}
                                                          alt={"logoutImage"}></img></div>
                </div>

            </div>
        )
    }
}

export default Logout;