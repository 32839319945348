import React, {Component} from 'react';
import {store} from "../App";
import {Analytics} from "aws-amplify";

class ErrorInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            messages: [],
            iserror: false,
            errorType: ("info" | "success" | "warning" | "danger"),
            errorTitle: "",
            errorMassages: ""

        };
    }

    componentDidMount() {
        store.subscribe(() => this.setState({messages: store.getState().websocketData, loaded: true}));
        this.interval = setInterval(() => {
            let delay = parseInt(Date.now() / 1000) - parseInt((this.state.messages.timestamp === undefined) ? (parseInt(Date.now() / 1000 + 1000)) : (parseInt(this.state.messages.timestamp)));
            if (delay !== 0 && delay < 10) {
                this.setState({
                    iserror: false,
                });

            } else if (delay < 0) {
                this.setState({
                    iserror: true,
                    errorType: "warning",
                    errorTitle: "Warning",
                    errorMassages: "Remote device currently is not available "
                });
                Analytics.record({name: 'noConnectiontoDevice'})
            } else if (delay > 10) {
                this.setState({
                    iserror: true,
                    errorType: "info",
                    errorTitle: "Info",
                    errorMassages: <>Connection lost please <strong style={{cursor: "pointer"}} href={"# "}
                                                                    onClick={() => (window.location.reload())}>reload</strong> the
                        page </>
                });
                Analytics.record({name: 'noConnectionlost'})

            }
        }, 5000);

    }

    render() {
        return (this.state.iserror ? (<div className={"alert alert-" + this.state.errorType}>
            <strong>{this.state.errorTitle}</strong> {this.state.errorMassages} </div>) : (<></>))
    }
}

export default ErrorInfo;