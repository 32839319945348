import React, {Component} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import {store} from "../App";

import Amplify from 'aws-amplify';
import Analytics from '@aws-amplify/analytics'
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);
Analytics.configure(awsconfig);


class MainChart extends Component {
    constructor(props) {
        super(props);
        this.chartComponent = React.createRef();
        this.state = {
            chartOptions: {
                chart: {
                    displayErrors: true,
                    zoomType: 'x',
                    height: 480,

                    events: {
                        load() {
                            this.showLoading();
                            setTimeout(this.hideLoading.bind(this), 400);
                        }
                    }
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        overflow: 'justify'
                    },
                },
                tooltip: {
                    valueSuffix: ''
                },
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value} W',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    title: {
                        text: 'Leistung',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true

                }, { // Secondary yAxis
                    gridLineWidth: 0,
                    title: {
                        text: 'Energie',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} kWh',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }

                }],
                legend: {
                    enabled: true
                },

                series: [{
                    name: 'Leistung',
                    yAxis: 0,
                    lineWidth: 0.5,

                }, {
                    name: 'Netzeinspeisung',
                    yAxis: 1,
                    //type: 'area',
                    lineWidth: 0.5,

                }, {
                    name: 'Netzbezug',
                    yAxis: 1,
                    //type: 'area',
                    lineWidth: 0.5,

                }],
            },
            hoverData: null,
            lastDevice: null,
            intervalName: "",
            interval: "",
            timeDropdownOpend: false,
            graphData: {},
            itsLoading: false,
            deviceId: 0,

        };
        Boost(Highcharts);
        Highcharts.setOptions(
            {
                global: {
                    useUTC: false
                }
            });
        Analytics.enable();

    }

    componentDidMount() {
        const chart = this.chartComponent.current.chart;
        let shiftFlag = chart.series[0].data.length > 40;
        this.chartComponent.current.chart.reflow();
        this.axesOne = store.subscribe(() =>
            (this.state.interval !== "yesterday" && this.state.interval !== "daybeforeyesterday") ?
                (chart.series[0].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, (Math.round((parseFloat(store.getState().websocketData[this.state.deviceId]["kWL1"]) + parseFloat(store.getState().websocketData[this.state.deviceId]["kWL2"]) + parseFloat(store.getState().websocketData[this.state.deviceId]["kWL3"]) * 10) / 10))], true, shiftFlag))
                : (console.log()));
        this.axesTow = store.subscribe(() =>
            (this.state.interval !== "yesterday" && this.state.interval !== "daybeforeyesterday") ?
                (chart.series[1].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId]["kWhneg_ges_c"])], true, shiftFlag))
                : (console.log()));
        this.axesthree = store.subscribe(() =>
            (this.state.interval !== "yesterday" && this.state.interval !== "daybeforeyesterday") ?
                (chart.series[2].addPoint([parseInt(store.getState().websocketData[this.state.deviceId].timestamp) * 1000, parseFloat(store.getState().websocketData[this.state.deviceId]["kWhpos_ges_c"])], true, shiftFlag))
                : (console.log()));
    }


    componentWillUnmount() {
        this.axesOne();
        this.axesTow();
        this.axesthree();
        this.setState(null);
    }

    updateSeries = (data, axes) => {
        let chart = this.state.chartOptions.series;
        chart[axes].data = data;
        this.setState({
            chartOptions: {
                series: chart
            }
        });
    };


    cangeIntervall = (colum1, colum2, colum3, interval, label, resolution1, resolution2) => {
        this.chartComponent.current.chart.showLoading('<img alt={"loading"} id="spinner" style="    max-width: 10%;" src="https://freejquery.com/wp-content/uploads/2019/07/spiner.gif"/>');

        Analytics.record({
            name: 'cangeTimeIntervall',
            attributes: {interval: interval}
        });

        this.setState({interval: interval});
        let table = (interval === "1m" || interval === "1j" || interval === "1w") ? ("/a") : ("");
        let table2 = (interval === "1j") ? ("/d/c") : ("/d");

        this.updateData(colum1, interval, label, 0, table, resolution1);
        this.updateData(colum2, interval, label, 1, table2, resolution2);
        this.updateData(colum3, interval, label, 2, table2, resolution2);
        this.setState({intervalName: label, timeDropdownOpend: false});

        this.sleep(600).then(() => {
            this.chartComponent.current.chart.hideLoading();
        })
    };

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.chartComponent.current.chart.reflow()
    }

    updateData = (colum, interval, label, axes, table, withoutresolution) => {
        fetch('https://api.ereneo.com/V1/Rest_API_Test?name=' + this.props.divice + table + '&column=' + colum + '&interval=' + interval + '&withoutresolution=' + withoutresolution)
            .then(res => res.json())
            .then((data) => {
                this.updateSeries(data, axes);
            })
            .catch(console.log);
    };

    render() {

        if (this.props.divice !== this.state.lastDevice) {
            this.setState({lastDevice: this.props.divice, deviceId: this.props.deviceID});
            this.updateData("KWL1 KWL2 KWL3", "24h", "Auswahl Zeitfenster", 0, "", "false");
            this.updateData("kWhneg_ges_c", "24h", "Auswahl Zeitfenster", 1, "/d", "false");
            this.updateData("kWhpos_ges_c", "24h", "Auswahl Zeitfenster", 2, "/d", "false");
            this.setState({intervalName: "Auswahl Zeitfenster", timeDropdownOpend: false});
            (this.chartComponent.current !== null) ? (this.chartComponent.current.chart.zoomOut()) : (console.log());
        }
        return (
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="dropdown float-right">
                            <button onClick={() => this.setState({timeDropdownOpend: !this.state.timeDropdownOpend})}
                                    className="dropbtn">{this.state.intervalName}</button>
                            <div id="myDropdown"
                                 className={"dropdown-content" + (this.state.timeDropdownOpend ? ' show' : '')}>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "1h", "1 Stunde", false, true)}>
                                            <div className=" text-secondary">Letzte 1 Stunde</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "1w", "Diese Woche", false, true)}>
                                            <div className=" text-secondary">Diese Woche</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "today", "Heute", false, false)}>
                                            <div className=" text-secondary">Heute</div>
                                        </a>
                                        {/* first line */}
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "3h", "Letzte 3 Stunde", false, true)}>
                                            <div className=" text-secondary">Letzte 3 Stunde</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "1m", "Dieser Monat", false, true)}>
                                            <div className=" text-secondary">Dieser Monat</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "yesterday", "Gestern", false, false)}>
                                            <div className=" text-secondary">Gestern</div>
                                        </a>
                                    </div>
                                    {/* second line */}
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "6h", "Letzte 6 Stunde", false, true)}>
                                            <div className=" text-secondary">Letzte 6 Stunde</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "1j", "Dieses Jahr", false, true)}>
                                            <div className=" text-secondary">Dieses Jahr</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "daybeforeyesterday", "Vorgestern", false, false)}>
                                            <div className=" text-secondary">Vorgestern</div>
                                        </a>
                                    </div>
                                    {/* fourth line */}
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "12h", "Letzten 12 Stunde", false, true)}>

                                            <div className="text-secondary">Letzten 12 Stunde</div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4">
                                        <a className="card" style={{cursor: "pointer"}} href={"# "}
                                           onClick={this.cangeIntervall.bind(this, "KWL1 KWL2 KWL3", "kWhneg_ges_c", "kWhpos_ges_c", "live", "Live Data", true, true)}>

                                            <div className="text-secondary">Live Data</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{height: "500px", width: "100%"}}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.chartOptions}
                            ref={this.chartComponent}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default MainChart;