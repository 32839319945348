import React from 'react'
import {DropTarget} from 'react-dnd'
import update from 'immutability-helper'
import Box from "./graphicParts/Box";
import {API, Auth, graphqlOperation} from "aws-amplify";
import $ from 'jquery';
import * as customQueries from "../graphql/queries";


class Container extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            boxes: {
                a: {top: 20, left: 80, title: 'Drag me around'},
                b: {top: 180, left: 20, title: 'Drag me too'},
            },
            loaded: false,
            idInfocus: "38e0d20f-c44f-4bce-8b8c-0a6121f00b51",
            cognitoId: 0,
            startGraphic: null,
            value: '',
            tracks: [
                {
                    source: "sun_wert"
                    , triggerIf: "< 10"
                    , aim: [
                        {
                            source: "monitoring_wert_1"
                            , triggerIf: "> 20"
                            , startTop: 168
                            , startLeft: 636
                            , stopTop: 259
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 1050
                            , orderOne: 1
                            , orderTowe: 1

                        }, {
                            source: "monitoring_wert_2"
                            , triggerIf: "> 20"
                            , startTop: 168
                            , startLeft: 636
                            , stopTop: 344
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 836
                            , orderOne: 1
                            , orderTowe: 0

                        }, {
                            source: "monitoring_wert_3"
                            , triggerIf: "> 20"
                            , startTop: 168
                            , startLeft: 636
                            , stopTop: 432
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 836
                            , orderOne: 1
                            , orderTowe: 0

                        }, {
                            source: "storage_wert_2"
                            , triggerIf: "> 0"
                            , startTop: 168
                            , startLeft: 636
                            , stopTop: 370
                            , stopLeft: 356
                            , BetweenTop: 259
                            , BetweenLeft: 356
                            , orderOne: 1
                            , orderTowe: 1

                        }, {
                            source: "grid_wert"
                            , triggerIf: "< -40"
                            , startTop: 168
                            , startLeft: 636
                            , stopTop: 259
                            , stopLeft: 120
                            , BetweenTop: 259
                            , BetweenLeft: 120
                            , orderOne: 1
                            , orderTowe: 1

                        }

                    ]


                }, {
                    source: "grid_wert"
                    , triggerIf: "> 50"
                    , aim: [
                        {
                            source: "monitoring_wert_1"
                            , triggerIf: "> 20"
                            , startTop: 259
                            , startLeft: 120
                            , stopTop: 259
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 1050
                            , orderOne: 1
                            , orderTowe: 1

                        }, {
                            source: "monitoring_wert_2"
                            , triggerIf: "> 20"
                            , startTop: 259
                            , startLeft: 120
                            , stopTop: 344
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 836
                            , orderOne: 1
                            , orderTowe: 0

                        }, {
                            source: "monitoring_wert_3"
                            , triggerIf: "> 20"
                            , startTop: 259
                            , startLeft: 120
                            , stopTop: 432
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 836
                            , orderOne: 1
                            , orderTowe: 0

                        }
                    ]


                }, {
                    source: "storage_wert_2"
                    , triggerIf: "< 10"
                    , aim: [
                        {
                            source: "monitoring_wert_1"
                            , triggerIf: "> 20"
                            , startTop: 370
                            , startLeft: 356
                            , stopTop: 259
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 1050
                            , orderOne: 1
                            , orderTowe: 1

                        }, {
                            source: "monitoring_wert_2"
                            , triggerIf: "> 20"
                            , startTop: 370
                            , startLeft: 356
                            , stopTop: 344
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 836
                            , orderOne: 1
                            , orderTowe: 0

                        }, {
                            source: "monitoring_wert_3"
                            , triggerIf: "> 20"
                            , startTop: 370
                            , startLeft: 356
                            , stopTop: 432
                            , stopLeft: 1050
                            , BetweenTop: 259
                            , BetweenLeft: 836
                            , orderOne: 1
                            , orderTowe: 0

                        }
                    ]


                }


            ]
        }
        this.handleChange = this.handleChange.bind(this);

    }

    AnimateBobel(name, startTop, startLeft,
                 stopTop, stopLeft, BetweenTop, BetweenLeft, orderOne, orderTowe) {
        let id = Math.floor((Math.random() * 100) + 1);
        $(".box2").append(` <div class='image' style='left: 1020px;top: 405px; z-index: 5;' id='bole` + id + `'><img src="https://portal.ereneo.de/images/dashboard/svg-27148x27.svg" alt="boble" style="  width: 10px; "/></div>`);
        name = "#bole" + id;
        let speed = 20;
        $(name).animate({top: startTop, left: startLeft,},
            0, "linear", function () {
            });
        if (orderOne === 1) {
            $(name).animate({top: BetweenTop}, Math.abs(BetweenTop - startTop) * speed, 'linear');
            $(name).animate({left: BetweenLeft}, Math.abs(BetweenLeft - startLeft) * speed, 'linear');
        } else {
            $(name).animate({left: BetweenLeft}, Math.abs(BetweenLeft - startLeft) * speed, 'linear');
            $(name).animate({top: BetweenTop}, Math.abs(BetweenTop - startTop) * speed, 'linear');
        }
        if (orderTowe === 1) {
            $(name).animate({left: stopLeft}, Math.abs(stopLeft - BetweenLeft) * speed, 'linear');
            $(name).animate({top: stopTop}, Math.abs(stopTop - BetweenTop) * speed, 'linear', function () {
                $(name).remove();
            });
        } else {
            $(name).animate({top: stopTop}, Math.abs(stopTop - BetweenTop) * speed, 'linear');
            $(name).animate({left: stopLeft}, Math.abs(stopLeft - BetweenLeft) * speed, 'linear', function () {
                $(name).remove();
            });
        }
    }


    componentWillUnmount() {
        this.setState(null);
        clearInterval(this.interval)

    }

    componentDidMount() {
        this.interval = setInterval(() => {
            Object.keys(this.state.tracks).map(source => {
                if (this.ifwithCustomConditon($('.' + this.state.tracks[source].source).html().replace(' ', '').replace('W', ''),
                    parseInt(this.state.tracks[source].triggerIf.replace(/^\D+/g, '')),
                    this.state.tracks[source].triggerIf.replace(' ', '').replace(/[0-9]/g, ''))) {
                    Object.keys(this.state.tracks[source]["aim"]).map(key => {
                        if (this.ifwithCustomConditon($('.' + this.state.tracks[source]["aim"][key].source).html().replace(' ', '').replace('W', ''),
                            parseInt(this.state.tracks[source]["aim"][key].triggerIf.replace(/^\D+/g, '')),
                            this.state.tracks[source]["aim"][key].triggerIf.replace(' ', '').replace(/[0-9]/g, '').replace('-', ''))) {
                            this.AnimateBobel("#1", this.state.tracks[source]["aim"][key].startTop, this.state.tracks[source]["aim"][key].startLeft, this.state.tracks[source]["aim"][key].stopTop, this.state.tracks[source]["aim"][key].stopLeft, this.state.tracks[source]["aim"][key].BetweenTop, this.state.tracks[source]["aim"][key].BetweenLeft, parseInt(this.state.tracks[source]["aim"][key].orderOne), parseInt(this.state.tracks[source]["aim"][key].orderTowe));
                        }
                    })
                }
            })


            // setTimeout(() => {
            //
            //
            //         if ($('.sun_wert').html() < 500) {
            //             if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 259, 1050, 259, 1050, 1, 1);
            //             }
            //             if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 353, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 432, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.storage_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 168, 536, 370, 356, 259, 356, 1, 1);
            //             }
            //             if ($('.grid_wert').html().replace(' ', '').replace('W', '') < -200) {
            //                 this.AnimateBobel("#1", 168, 536, 259, 120, 259, 120, 1, 1);
            //             }
            //
            //         }
            //
            //         if ($('.grid_wert').html().replace(' ', '').replace('W', '') > 500) {
            //             if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 259, 120, 259, 1050, 259, 1050, 1, 1);
            //             }
            //             if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 259, 120, 353, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 259, 120, 432, 1050, 259, 836, 1, 0);
            //             }
            //         }
            //         if ($('.storage_wert_2').html().replace(' ', '').replace('W', '') < 500) {
            //             if ($('.monitoring_wert_1').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 370, 356, 259, 1050, 259, 1050, 1, 1);
            //             }
            //             if ($('.monitoring_wert_2').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 370, 356, 353, 1050, 259, 836, 1, 0);
            //             }
            //             if ($('.monitoring_wert_3').html().replace(' ', '').replace('W', '') > 500) {
            //                 this.AnimateBobel("#1", 370, 356, 432, 1050, 259, 836, 1, 0);
            //             }
            //
            //         }
            //
            //
            //
            //
            //
            // }, 2000)
        }, 5000);
        Auth.currentSession().then(data => this.setState({cognitoId: data.idToken.payload.sub})).catch(err => console.log(err))

    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }


    ifwithCustomConditon(valueLeft, valueRight, condition) {
        if (condition === "===") {
            return valueLeft === valueRight;
        } else if (condition === ">") {
            return valueLeft > valueRight;
        } else if (condition === "<") {
            return valueLeft < valueRight;
        } else if (condition === "!==") {
            return valueLeft !== valueRight;
        } else if (condition === "<=") {
            return valueLeft <= valueRight;
        } else if (condition === ">=") {
            return valueLeft >= valueRight;
        }

    }


    async getData() {
        const allTodos = await API.graphql(graphqlOperation(customQueries.listStartGraphics, {
            cognitoId: this.state.cognitoIdm,
            limit: 100
        }));
        console.log(allTodos.data.listStartGraphics.items);
        this.setState({boxes: allTodos.data.listStartGraphics.items});
        this.setState({loaded: true});
    }

    render() {
        if (this.state.loaded !== true) {
            this.getData()
        }
        const {hideSourceOnDrag, connectDropTarget} = this.props
        return connectDropTarget(
            <div className="box2">
                {Object.keys(this.state.tracks).map(source => {
                    return (<>
                            {Object.keys(this.state.tracks[source]["aim"]).map(key => {
                                return (
                                    <div>
                                        <div className={"image"} style={{
                                            left: this.state.tracks[source]["aim"][key].startLeft + 'px',
                                            top: this.state.tracks[source]["aim"][key].startTop + 'px'
                                        }}>
                                            <img
                                                src={"https://cdn2.iconfinder.com/data/icons/budicon-interface-layout-2/16/53-interface_-_cross_cancel-512.png"}
                                                style={{width: '10px'}} alt={"img1"}/>
                                        </div>
                                        <div className={"image"} style={{
                                            left: this.state.tracks[source]["aim"][key].stopLeft + 'px',
                                            top: this.state.tracks[source]["aim"][key].stopTop + 'px'
                                        }}>
                                            <img
                                                src={"https://cdn2.iconfinder.com/data/icons/budicon-interface-layout-2/16/53-interface_-_cross_cancel-512.png"}
                                                style={{width: '10px'}} alt={"img1"}/>
                                        </div>
                                        <div className={"image"} style={{
                                            left: this.state.tracks[source]["aim"][key].BetweenLeft + 'px',
                                            top: this.state.tracks[source]["aim"][key].BetweenTop + 'px'
                                        }}>
                                            <img
                                                src={"https://cdn2.iconfinder.com/data/icons/budicon-interface-layout-2/16/53-interface_-_cross_cancel-512.png"}
                                                style={{width: '10px'}} alt={"img1"}/>
                                        </div>
                                        {(parseInt(this.state.tracks[source]["aim"][key].orderOne) === 1) ? (
                                            <div className="line" style={{
                                                width: '3px',
                                                height: '50px',
                                                border: '2px dotted #ffff',

                                                left: this.state.tracks[source]["aim"][key].startLeft + 3 + 'px',
                                                top: this.state.tracks[source]["aim"][key].startTop + 14 + 'px'
                                            }}/>
                                        ) : (<div className="line" style={{
                                            width: '50px',
                                            height: '3px',
                                            border: '2px dotted #ffff',
                                            left: this.state.tracks[source]["aim"][key].startLeft + 3 + 'px',
                                            top: this.state.tracks[source]["aim"][key].startTop + 14 + 'px'
                                        }}/>)
                                        }
                                        {(parseInt(this.state.tracks[source]["aim"][key].orderTowe) === 1) ? (
                                            <div className="line" style={{
                                                width: '3px',
                                                height: '50px',
                                                border: '2px dotted #ffff',

                                                left: this.state.tracks[source]["aim"][key].BetweenLeft + 3 + 'px',
                                                top: this.state.tracks[source]["aim"][key].BetweenTop + 14 + 'px'
                                            }}/>
                                        ) : (<div className="line" style={{
                                            width: '50px',
                                            height: '3px',
                                            border: '2px dotted #ffff',
                                            left: this.state.tracks[source]["aim"][key].BetweenLeft + 3 + 'px',
                                            top: this.state.tracks[source]["aim"][key].BetweenTop + 14 + 'px'
                                        }}/>)}

                                    </div>

                                )
                            })
                            }
                        </>
                    )
                })
                }

                {this.state.loaded ? (
                    Object.keys(this.state.boxes).map(key => {
                        return (

                            <Box
                                item={this.state.boxes[key]}
                                key={this.state.boxes[key].id}
                                id={key}
                                left={this.state.boxes[key].left}
                                top={this.state.boxes[key].top}
                                hideSourceOnDrag={hideSourceOnDrag}
                            >
                            </Box>
                        )
                    })) : (<div>loding</div>)}
                <div style={{
                    width: "430px",
                    height: "600px",
                    backgroundColor: "white",
                    overflowX: 'auto',
                    textAlign: 'justify',
                    padding: 20,
                    marginLeft: "-462px"
                }}>
                    <table style={{
                        width: "370px",
                        height: "500px",
                        backgroundColor: "white",
                        overflowX: 'auto',
                        textAlign: 'justify'
                    }}>
                        <button type="button" className="btn btn-primary" onClick={() => this.addElement("image")}>Add
                            Image
                        </button>
                        <button type="button" className="btn btn-primary" onClick={() => this.addElement("line")}>Add
                            Line
                        </button>
                        <button type="button" className="btn btn-primary"
                                onClick={() => this.addElement("valuebox")}>Add Valuebox
                        </button>
                        {Object.keys(this.state.boxes).map(key =>
                            <tr>
                                <th style={{backgroundColor: (this.state.idInfocus === this.state.boxes[key].id) ? ("Cornsilk") : ("white")}}>
                                    <h7>{this.state.boxes[key].id}</h7>
                                    <button type="button" className="btn btn-danger"
                                            onClick={() => this.removeElement(key)}>Remove
                                    </button>

                                    <hr/>
                                    <div className="row form-group">
                                        <div className="col-3">
                                            <small>left:</small>
                                            <input
                                                className={"form-control"}
                                                type={"number"}

                                                value={this.state.boxes[key].left}
                                                onChange={e => {
                                                    this.moveBox(key, e.target.value, this.state.boxes[key].top)
                                                }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <small>top:</small>
                                            <input
                                                className={"form-control"}

                                                type={"number"}
                                                value={this.state.boxes[key].top}
                                                onChange={e => {
                                                    this.moveBox(key, this.state.boxes[key].left, e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <small>width:</small>
                                            <input
                                                className={"form-control"}

                                                type={"number"}
                                                value={this.state.boxes[key].width}
                                                onChange={e => {
                                                    this.cangeValue(e, key, "width")
                                                }}
                                            />
                                        </div>
                                        <div className="col-3">
                                            <small>height:</small>
                                            <input
                                                className={"form-control"}

                                                type={"number"}
                                                value={this.state.boxes[key].height}
                                                onChange={e => {
                                                    this.cangeValue(e, key, "height")
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {(this.state.boxes[key].typ === "image") ? (
                                        <div><small>imagepath:</small>
                                            <input
                                                className={"form-control"}

                                                type={"text"}
                                                value={this.state.boxes[key].imagepath}
                                                onChange={e => {
                                                    this.cangeValue(e, key, "imagepath")
                                                }}
                                            />
                                        </div>) : (<div></div>)}
                                    <small>typ:</small>
                                    <select className={"form-control"}
                                            value={this.state.boxes[key].typ}
                                            onChange={e => {
                                                this.cangeValue(e, key, "typ")
                                            }}
                                    >
                                        <option disabled value="image">image</option>
                                        <option disabled value="valuebox">valuebox</option>
                                        <option disabled value="line">line</option>
                                    </select>
                                    {(this.state.boxes[key].typ === "valuebox") ? (
                                        <div className="row form-group">
                                            <div className="col-3">
                                                <small>deviceID:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.boxes[key].deviceID}
                                                    onChange={e => {
                                                        this.cangeValue(e, key, "deviceID")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <small>valueName:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"text"}
                                                    value={this.state.boxes[key].valueName}
                                                    onChange={e => {
                                                        this.cangeValue(e, key, "valueName")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <small>valueAdd:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"text"}
                                                    value={this.state.boxes[key].valueAdd}
                                                    onChange={e => {
                                                        this.cangeValue(e, key, "valueAdd")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <small>Name:</small>
                                                <input
                                                    className={"form-control"}

                                                    type={"text"}
                                                    value={this.state.boxes[key].valueIdentifierName}
                                                    onChange={e => {
                                                        this.cangeValue(e, key, "valueIdentifierName")
                                                    }}
                                                /></div>
                                            <div className="col-3">
                                                <small>valueUnit:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"text"}
                                                    value={this.state.boxes[key].valueUnit}
                                                    onChange={e => {
                                                        this.cangeValue(e, key, "valueUnit")
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    ) : (<div></div>)}
                                    <hr/>
                                </th>
                            </tr>
                        )}
                        {Object.keys(this.state.tracks).map(key =>
                            <tr>
                                <th>
                                    <h7>{this.state.tracks[key].source}</h7>
                                    <hr/>
                                    <div className="row form-group">
                                        <div className="col-12">
                                            <small>triggerIf:</small>
                                            <input
                                                className={"form-control"}
                                                type={"text"}
                                                value={this.state.tracks[key].triggerIf}
                                                onChange={e => {
                                                    this.cangeTrackValue(e, key, null, "triggerIf")
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <p>{this.state.tracks[key].triggerIf}</p>
                                    {Object.keys(this.state.tracks[key].aim).map(item =>
                                        <div className="row form-group">
                                            <div className="col-12">


                                                <small>source:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"text"}
                                                    value={this.state.tracks[key].aim[item].source}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "source")
                                                    }}
                                                />

                                            </div>
                                            <div className="col-3">


                                                <small>triggerIf:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"text"}
                                                    value={this.state.tracks[key].aim[item].triggerIf}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "triggerIf")
                                                    }}
                                                />

                                            </div>
                                            <div className="col-3">
                                                <small>startTop:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.tracks[key].aim[item].startTop}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "startTop")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <small>startLeft:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.tracks[key].aim[item].startLeft}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "startLeft")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-3">
                                                <small>stopTop:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.tracks[key].aim[item].stopTop}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "stopTop")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <small>stopLeft:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.tracks[key].aim[item].stopLeft}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "stopLeft")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <small>BetweenTop:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.tracks[key].aim[item].BetweenTop}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "BetweenTop")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <small>BetweenLeft:</small>
                                                <input
                                                    className={"form-control"}
                                                    type={"number"}
                                                    value={this.state.tracks[key].aim[item].BetweenLeft}
                                                    onChange={e => {
                                                        this.cangeTrackValue(e, key, item, "BetweenLeft")
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <small>orderOne:</small>
                                                <select className={"form-control"}
                                                        value={this.state.tracks[key].aim[item].orderOne}
                                                        onChange={e => {
                                                            this.cangeTrackValue(e, key, item, "orderOne")
                                                        }}
                                                >
                                                    <option value="0">Rechts</option>
                                                    <option value="1">Unten</option>
                                                </select>
                                            </div>
                                            <div className="col-6">
                                                <small>orderTowe:</small>
                                                <select className={"form-control"}
                                                        value={this.state.tracks[key].aim[item].orderTowe}
                                                        onChange={e => {
                                                            this.cangeTrackValue(e, key, item, "orderTowe")
                                                        }}
                                                >
                                                    <option value="0">Rechts</option>
                                                    <option value="1">Unten</option>
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    <hr/>
                                </th>
                            </tr>
                        )}

                    </table>
                </div>
            </div>)
    }

    moveBox(id, left, top) {

        this.setState({idInfocus: this.state.boxes[id].id});
        this.setState(
            update(this.state, {
                boxes: {
                    [id]: {
                        $merge: {left, top},
                    },
                },
            }),
        )
    }

    cangeValue(e, key, valueName) {
        let old = this.state.boxes;

        old[key][valueName] = e.target.value
        old[key].left = parseInt(this.state.boxes[key].left) + 1
        old[key].top = parseInt(this.state.boxes[key].top) + 1
        this.setState({
            boxes: old
        })

        this.moveBox(key, this.state.boxes[key].left - 1, this.state.boxes[key].top - 1);
    }


    cangeTrackValue(e, key, item, valueName) {
        let old = this.state.tracks;
        console.log(e.target.value)

        if (item === null) {
            old[key][valueName] = e.target.value;


        } else {
            old[key].aim[item][valueName] = e.target.value;


        }


        this.setState({
            tracks: old
        })
    }

    removeElement(key) {
        let old = this.state.boxes

        old.splice(key, 1)

        this.setState({boxes: old})


    }

    addElement(type) {
        let old = this.state.boxes
        let theme = ""
        switch (type) {
            case "image":
                theme = {
                    cognitoId: "ea6bd363-0693-44a1-825f-b4a02cf1532d",
                    typ: "image",
                    width: 110,
                    height: null,
                    left: 30,
                    top: 212,
                    imagepath: "https://portal.ereneo.de/images/dashboard/svg-135055x133.svg",
                    imagealttag: "img1",
                    deviceID: null,
                    valueName: null,
                    valueUnit: null,
                    valueAdd: null,
                    valueIdentifierName: null,
                };
                break;
            case "line":
                theme = {
                    cognitoId: "ea6bd363-0693-44a1-825f-b4a02cf1532d",
                    typ: "line",
                    width: 110,
                    height: 3,
                    left: 30,
                    top: 212,
                    imagepath: null,
                    imagealttag: null,
                    deviceID: null,
                    valueName: null,
                    valueUnit: null,
                    valueAdd: null,
                    valueIdentifierName: null,
                };
                break;
            case "valuebox":
                theme = {
                    cognitoId: "ea6bd363-0693-44a1-825f-b4a02cf1532d",
                    typ: "valuebox",
                    width: null,
                    height: null,
                    left: 30,
                    top: 212,
                    imagepath: null,
                    imagealttag: null,
                    deviceID: 1,
                    valueName: "kWL1",
                    valueUnit: "W",
                    valueAdd: "kWL2+kWL3",
                    valueIdentifierName: null,
                };
                break;


        }

        old.push(theme)

        this.setState({boxes: old})


    }
}

export default DropTarget(
    'box',
    {
        drop(props, monitor, component) {
            if (!component) {
                return
            }
            const item = monitor.getItem()
            const delta = monitor.getDifferenceFromInitialOffset()
            const left = Math.round(parseInt(item.left) + parseInt(delta.x))
            const top = Math.round(parseInt(item.top) + parseInt(delta.y))
            component.moveBox(item.id, left, top)
        },
    },
    connect => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(Container)



