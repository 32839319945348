import React, {Component} from "react";
import MainChart from "../parts/MainChart"
import LiveData from "../parts/LiveData";
import '../../node_modules/react-grid-layout/css/styles.css'

//import {Link} from "react-router-dom";
class CGET340 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastDevice: "",
            milestoneData: {
                timestamp: "2020-01-16 16:35:01",
                AL1: 0,
                AL2: 0,
                AL3: 0,
                0: 0,
                PFL2: 0,
                PFL3: 0,
                Pfsys: 0,
                VL1_L2: 0,
                VL1_N: 0,
                VL2_L3: 0,
                VL2_N: 0,
                VL3_L1: 0,
                VL3_N: 0,
                VL_Nsys: 0,
                kWL1: 0,
                kWL2: 0,
                kWL3: 0,
                kWhnegL1c: 0,
                kWhnegL2c: 0,
                kWhnegL3c: 0,
                kWhposL1c: 0,
                kWhposL2c: 0,
                kWhposL3c: 0,
                kWhposL2: 0,
                kWhposL3: 0,
            },
            currentDevice: "",
        };
    }

    getTitle(id) {
        switch (parseInt(id)) {
            case 1:
                return "Netzanschluss";
            case 2:
                return "";
            case 3:
                return "PV-Anlage";
            case 4:
                return "Hausverbrauch";
            case 5:
                return "Ladestation Elektroauto";
            case 6:
                return "Waschmaschine";
            case 7:
                return "ereneo sun storage";
            default:
                return "-";
        }
    }
    render() {
        const currentDevice = this.props.installationId + '/' + this.props.typID + '/' + this.props.deviceID;
        if (currentDevice !== this.state.lastDevice) {
            this.setState({lastDevice: currentDevice});
            fetch('https://api.ereneo.com/V1/api_get_milestone_data?name=' + this.props.installationId + '/' + this.props.typID + '/' + this.props.deviceID)
                .then(res => res.json())
                .then((data) => {
                    this.setState({milestoneData: data});
                })
                .catch(console.log);
        }

        // You can use them as regular CSS styles
        return (<div className="content mt-3" style={{padding: '1% 3% 1% 3%'}}>
            <div style={{padding: '0% 1% 0% 1%'}}>
                <div style={{
                    fontFamily: '"Roboto", serif',
                    marginBottom: '0px',
                    marginTop: '-10px',
                }}>
                    <div className="col-lg-9"><h2 style={{
                        fontFamily: '"Roboto", serif',
                        marginBottom: '-5px',
                        marginLeft: '-1%',
                    }}>{this.getTitle(this.props.deviceID)} - Übersicht </h2></div>

                    {/*<div className="col-lg-3">*/}
                    {/*    <ul className="nav nav-pills float-right" style={{marginBottom: '-5px'}}>*/}
                    {/*        <li className="nav-item  active show">*/}
                    {/*            <div className="nav-link" id="pills-home-tab" data-toggle="pill"*/}
                    {/*                 role="tab"*/}
                    {/*                 aria-controls="pills-home" aria-selected="true">Übersicht*/}
                    {/*            </div>*/}
                    {/*        </li>*/}
                    {/*        <li className="nav-item">*/}
                    {/*            <Link*/}
                    {/*                to={"./erweitert"}>*/}
                    {/*                <div className="nav-link" id="pills-profile-tab" data-toggle="pill"*/}
                    {/*                     role="tab" aria-controls="pills-profile" aria-selected="false">Erweitert*/}
                    {/*                </div>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                </div>

            </div>

            <div className="col-lg-12">
                <hr/>
            </div>

            <MainChart divice={currentDevice} deviceID={this.props.deviceID}/>

            <div className=" col-lg-3">
                <div className="card">
                    <div className="card-body">
                        <div className="clearfix">
                            <i className="fa fa-cogs bg-flat-color-5 p-3 font-2xl mr-3 float-left text-light"/>
                            <div
                                className="text-muted text-uppercase font-weight-bold font-xs ">GESAMTLEISTUNG:
                            </div>
                            <div className="h5 text-secondary mb-0 mt-1">
                                <LiveData device={currentDevice} deviceID={this.props.deviceID} name={"kWL1"}
                                          decimals={1}
                                          add={["kWL2", "kWL3"]} unit={"W"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-lg-3">
                <div className="card">
                    <div className="card-body">
                        <div className="clearfix">
                            <i className="fa fa-cogs bg-flat-color-5 p-3 font-2xl mr-3 float-left text-light"/>
                            <div
                                className="text-muted text-uppercase font-weight-bold font-xs ">PHASE 1:
                            </div>
                            <div className="h5 text-secondary mb-0 mt-1">
                                <LiveData device={currentDevice} deviceID={this.props.deviceID} name={"kWL1"}
                                          decimals={1}
                                          add={null} unit={"W"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3">
                <div className="card">
                    <div className="card-body">
                        <div className="clearfix">
                            <i className="fa fa-cogs bg-flat-color-5 p-3 font-2xl mr-3 float-left text-light"/>
                            <div
                                className="text-muted text-uppercase font-weight-bold font-xs ">PHASE 2:
                            </div>
                            <div className="h5 text-secondary mb-0 mt-1">
                                <LiveData device={currentDevice} deviceID={this.props.deviceID} name={"kWL2"}
                                          decimals={1}
                                          add={null} unit={"W"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" col-lg-3">
                <div className="card">
                    <div className="card-body">
                        <div className="clearfix">
                            <i className="fa fa-cogs bg-flat-color-5 p-3 font-2xl mr-3 float-left text-light"/>
                            <div
                                className="text-muted text-uppercase font-weight-bold font-xs ">PHASE 3:
                            </div>
                            <div className="h5 text-secondary mb-0 mt-1">
                                <LiveData device={currentDevice} deviceID={this.props.deviceID} name={"kWL3"}
                                          decimals={1}
                                          add={null} unit={"W"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <strong className="card-title">Energie</strong>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col"/>
                                <th scope="col">Heute</th>
                                <th scope="col">Gestern</th>
                                <th scope="col"/>
                                <th scope="col">Dieser Monat</th>
                                <th scope="col">Letzter Monat</th>
                                <th scope="col"/>
                                <th scope="col">Dieses Jahr</th>
                                <th scope="col">Letztes Jahr</th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Netzbezug:</th>
                                <td>
                                    {parseFloat(this.state.milestoneData.kWhpos_ges_c).toFixed(1)} kWh
                                </td>
                                <td>
                                    {parseFloat(this.state.milestoneData.kWhpos_ges_c_gest).toFixed(1)} kWh
                                </td>
                                <td/>
                                <td>
                                    {parseFloat(this.state.milestoneData.kWhpos_ges_c_thismoth + this.state.milestoneData.kWhpos_ges_c).toFixed(1)} kWh
                                </td>
                                <td>
                                    {parseFloat(this.state.milestoneData.kWhpos_ges_c_lastmoth).toFixed(1)} kWh
                                </td>
                                <td/>
                                <td>
                                    {parseFloat(this.state.milestoneData.kWhpos_ges_c_thisyear + this.state.milestoneData.kWhpos_ges_c).toFixed(1)} kWh
                                </td>
                                <td>
                                    {parseFloat(this.state.milestoneData.kWhpos_ges_c_lastyear).toFixed(1)} kWh
                                </td>
                            </tr>

                            <tr>
                                <th scope="row">Netzeinspeisung:</th>
                                <td>
                                    - {parseFloat(this.state.milestoneData.kWhneg_ges_c).toFixed(1)} kWh
                                </td>
                                <td>
                                    - {parseFloat(this.state.milestoneData.kWhneg_ges_c_gest).toFixed(1)} kWh
                                </td>
                                <td/>
                                <td>
                                    - {parseFloat(this.state.milestoneData.kWhneg_ges_c_thismoth + this.state.milestoneData.kWhneg_ges_c).toFixed(1)} kWh
                                </td>
                                <td>
                                    - {parseFloat(this.state.milestoneData.kWhneg_ges_c_lastmoth).toFixed(1)} kWh
                                </td>
                                <td/>
                                <td>
                                    - {parseFloat(this.state.milestoneData.kWhneg_ges_c_thisyear + this.state.milestoneData.kWhneg_ges_c).toFixed(1)} kWh
                                </td>
                                <td>
                                    - {parseFloat(this.state.milestoneData.kWhneg_ges_c_lastyear).toFixed(1)} kWh

                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>


            </div>
            <style media="screen"
                   dangerouslySetInnerHTML={{__html: "\n        /* Dropdown Button */\n        .dropbtn {\n            background-color: #3498DB;\n            color: white;\n            padding: 6px;\n            font-size: 15px;\n            border: none;\n            cursor: pointer;\n            width: 190px;\n        }\n\n        /* Dropdown button on hover & focus */\n        .dropbtn:hover, .dropbtn:focus {\n            background-color: #2774a8;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);\n\n        }\n\n        /* The container <div> - needed to position the dropdown content */\n        .dropdown {\n            position: relative;\n            display: inline-block;\n        }\n\n        /* Dropdown Content (Hidden by Default) */\n        .dropdown-content {\n            display: none;\n            padding: 13px;\n            position: absolute;\n            background-color: #f1f1f1;\n            width: 50vh;\n            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);\n            z-index: 1;\n            right: 0%;\n        }\n\n        /* Links inside the dropdown */\n        .dropdown-content a {\n            color: black;\n            padding: 12px 16px;\n            text-decoration: none;\n            display: block;\n        }\n\n        /* Change color of dropdown links on hover */\n        .dropdown-content a:hover {\n            background-color: #ddd\n        }\n\n        /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */\n        .show {\n            display: block;\n        }\n    "}}/>
        </div>);
    }
}

export default CGET340;
