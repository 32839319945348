import React from "react";
import {BrowserRouter as Router, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import "../src/parts/style.scss"

import LefPanel from "./parts/lefPanel";
import StartPage from "./parts/StartPage";
import CGET340 from "./parts/CGET340";
import Amplify, {Analytics, Auth, PubSub} from 'aws-amplify';
import awsconfig from './aws-exports';
import {withAuthenticator} from 'aws-amplify-react';
import CGET340Expanded from "./parts/CGET340Expanded";
import Settings from "./parts/Settings";
import {createStore} from "redux";
import todoApp from "./parts/reducers";
import {addTodo} from "./parts/actions";
import {AWSIoTProvider} from "@aws-amplify/pubsub/lib/Providers";
import ErrorInfo from "./parts/ErrorInfo";
import loginUI from "./loginUI";
import Admin from "./parts/Admin";

Amplify.configure(awsconfig);

export const ThemeContext = React.createContext('light');
export const store = createStore(todoApp, window.STATE_FROM_SERVER);// Log the initial state

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            messages: [],
            userGroups: [],
            userAttributes: ""
        };
    }

    async componentDidMount() {


        const UpdataData = (data) => {
            store.dispatch(addTodo(data.value));
        };
        Amplify.addPluggable(new AWSIoTProvider({
            aws_pubsub_region: 'eu-central-1',
            aws_pubsub_endpoint: 'wss://amwb6lu2ntvj4-ats.iot.eu-central-1.amazonaws.com/mqtt',
        }));
        PubSub.subscribe('test').subscribe({
            next: data => UpdataData(data),
            error: error => console.error(error),
            close: () => console.log('Done'),
        });

        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
                this.setState({userGroups: user.signInUserSession.accessToken.payload["cognito:groups"]})
                Analytics.updateEndpoint({
                    // demographic: {
                    //     appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
                    //     locale: 'xxxxxx', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
                    //     make: 'xxxxxx', // The manufacturer of the endpoint device, such as Apple or Samsung.
                    //     model: 'xxxxxx', // The model name or number of the endpoint device, such as iPhone.
                    //     modelVersion: 'xxxxxx', // The model version of the endpoint device.
                    //     platform: 'xxxxxx', // The platform of the endpoint device, such as iOS or Android.
                    //     platformVersion: 'xxxxxx', // The platform version of the endpoint device.
                    //     timezone: 'xxxxxx' // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
                    // },
                    userAttributes: {
                        userId: [user.username]
                        // ...
                    }
                })
            }
        ).catch(err => console.log(err));


    }


    render() {
        return (
            <Router>
                <div>
                    <link rel="stylesheet" href={"https://portal.ereneo.de/assets/css/bootstrap.min.css"}/>
                    <link rel="stylesheet" href={"https://portal.ereneo.de/assets/scss/style.css"}/>
                    <LefPanel/>
                    <style type="text/css" media="screen"
                           dangerouslySetInnerHTML={{__html: "\n    @media only screen and (max-width: 900px) {\n\n        .menuebox {\n            margin-left: auto;\n            margin-right: auto;\n\n            padding: 11%;\n\n        }\n\n\n    }\n    @media only screen and (min-width: 900px) {\n\n        .menuebox {\n\n\n            margin-left:-10px\n\n        }\n\n\n    }\n\n\n"}}/>
                    <div id="right-panel" className="right-panel">
                        <ErrorInfo/>

                        <Switch>

                            {
                                (this.state.userGroups.includes("admins")) ? (
                                    <Route path="/admin">
                                        <Admin/>
                                    </Route>


                                ) : (<></>)
                            }

                            <Route path="/settings">
                                <Settings/>
                            </Route>
                            <Route path="/device">
                                <DeviceOverview data={this.state.messages} loaded={this.state.loaded}/>
                            </Route>
                            <Route path="/">
                                <StartPage onClick={Analytics.record({name: 'ClickOnLogo'})}/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Router>);
    }
}


const MyTheme = {
    ...loginUI,
}


export default withAuthenticator(App, false, [], null, MyTheme,);


function DeviceOverview(props) {
    let match = useRouteMatch();
    return (
        <Switch>
            <Route path={`${match.path}/:installationId/:typID/:deviceID/erweitert`}>
                <Expanded data={props.data} loaded={props.loaded}/>
            </Route>
            <Route path={`${match.path}/:installationId/:typID/:deviceID`}>
                <Device data={props.data} loaded={props.loaded}/>
            </Route>

            <Route path={match.path}>
                <h3>Please select a topic.</h3>
            </Route>
        </Switch>
    );
}

function Device() {
    let {installationId, typID, deviceID} = useParams();
    Analytics.record({
        name: 'DeviceCange',
        attributes: {installationId: installationId, typID: typID, deviceID: deviceID}
    });

    if (typID === "CGET340") {
        return <CGET340 installationId={installationId} typID={typID} deviceID={deviceID}/>
    } else {
        return <h3>Requested topic ID: {installationId} {typID} {deviceID}</h3>;
    }
}

function Expanded() {
    let {installationId, typID, deviceID} = useParams();
    if (typID === "CGET340") {
        return <CGET340Expanded installationId={installationId} typID={typID} deviceID={deviceID}/>
    } else {
        return <h3>Requested topic ID: {installationId} {typID} {deviceID}</h3>;
    }
}
